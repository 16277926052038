var styleShape = {};
styleShape.stroke = '#000000';
styleShape.strokeWidth = 0;
styleShape.borderRadius = 0;
styleShape.fill = '#ffffff';
styleShape.dragAssist = 'box'; // box, origin, custom
styleShape.moveOriginal = false;
styleShape.assistFill = '#ffffff';
styleShape.assistStroke = '#000000';
styleShape.assistStrokeWidth = 1;

var styleText = {};
styleText.fontSize = '45px';
styleText.fontFamily = "Arial";
styleText.lineHeight = 25;
styleText.fontWeight = 'normal';
styleText.fill = '#000000';
styleText.wordWrap = true;
styleText.maxLength = 25;
styleText.horizontalAlign = 'center'; //left, center, right
styleText.verticalAlign = 'middle'; //top, middle, bottom
styleText.position = [0, 0]; //inside, outside

var styleAdShape = {};
styleAdShape.stroke = '#ffffff';
styleAdShape.strokeWidth = 1;
styleAdShape.fill = 'none';
styleAdShape.fontSize = '9px';
styleAdShape.fontFamily = 'Arial'; //[horizontal, Vertical] ** Horizontal * 0 = Left to Right, 1 = Right to Left ** Vertical * 0 = Top to Bottom, 1 = Bottom to Top

var dimentionStyle = {};
dimentionStyle.fontSize = '25px';
dimentionStyle.fontFamily = "Arial";
dimentionStyle.fontWeight = "normal";
dimentionStyle.textColor = '#000000';
dimentionStyle.textAlign = 0; // 0, 1 (0 = left/top, 1 = right/bottom)
dimentionStyle.stroke = '#000000';
dimentionStyle.strokeWidth = 3;
dimentionStyle.endPoints = 'none';