class EPM extends d3Graph {
    constructor(container, element, width, height) {
      super(container, element, width, height);
    }
    instance(type, id, x, y, w, h, style, relative) {
      return this.insertVertex(type, id, x, y, w, h, style, relative);
    }
  
    group(id, x, y, w, h, style, relative) {
      return this.insertVertex("group", id, x, y, w, h, style, relative);
    }
  }
  
  class CHART extends d3Graph {
    constructor(container, width, height) {
      super(container, width, height);
    }
    instance(type, id, x, y, w, h, style, relative) {
      return this.insertVertex(type, id, x, y, w, h, style, relative);
    }
  
    group(id, x, y, w, h, style, relative) {
      return this.insertVertex("group", id, x, y, w, h, style, relative);
    }
  }
  
  d3Vertex.prototype.instance = function (type, id, x, y, w, h, style, relative) {
    return this.insertVertex(type, id, x, y, w, h, style, relative);
  };
  
  d3Vertex.prototype.group = function (id, x, y, w, h, style, relative) {
    return this.insertVertex("group", id, x, y, w, h, style, relative);
  };
  
  d3Vertex.prototype.updateInstance = function (id, x, y, w, h) {
    return this.updateVertex(id, x, y, w, h);
  };
  