var CUBICLE_TERM_LOC_RULE__ACB = {
    "ACB_IC+IC-T & OG-T": ["Top"],
    "ACB_IC+IC-T & OG-B": ["Top"],
    "ACB_IC+IC-B & OG-B": ["Bottom"],
    "ACB_IC+IC-B & OG-T": ["Bottom"],
    "ACB_OG+IC-T & OG-T": ["Top"],
    "ACB_OG+IC-T & OG-B": ["Bottom"],
    "ACB_OG+IC-B & OG-B": ["Bottom"],
    "ACB_OG+IC-B & OG-T": ["Top"],
    "ACB_BC+IC-T & OG-T": ["NA"],
    "ACB_BC+IC-T & OG-B": ["NA"],
    "ACB_BC+IC-B & OG-B": ["NA"],
    "ACB_BC+IC-B & OG-T": ["NA"],
  };
  
  var CUBICLE_WIDTH__CUBICLE_DEPTH__ACB = {
    "ACB_IC+Top+800+Bottom": [600, 800],
    "ACB_IC+Top+1000+Bottom": [600, 800],
    "ACB_IC+Top+1250+Bottom": [600, 800],
    "ACB_IC+Top+1600+Bottom": [600, 800],
    "ACB_IC+Top+800+Top": [600, 1200],
    "ACB_IC+Top+1000+Top": [600, 1200],
    "ACB_IC+Top+1250+Top": [600, 1200],
    "ACB_IC+Top+1600+Top": [600, 1200],
    "ACB_IC+Top+2000+Bottom": [800, 800],
    "ACB_IC+Top+2500+Bottom": [800, 800],
    "ACB_IC+Top+2000+Top": [800, 1200],
    "ACB_IC+Top+2500+Top": [800, 1200],
    "ACB_IC+Top+3200+Bottom": [1000, 1000],
    "ACB_IC+Top+4000+Bottom": [1000, 1000],
    "ACB_IC+Top+3200+Top": [1000, 1400],
    "ACB_IC+Top+4000+Top": [1000, 1400],
    "ACB_OG+Top+800+Bottom": [600, 800],
    "ACB_OG+Top+1000+Bottom": [600, 800],
    "ACB_OG+Top+1250+Bottom": [600, 800],
    "ACB_OG+Top+1600+Bottom": [600, 800],
    "ACB_OG+Top+800+Top": [600, 1200],
    "ACB_OG+Top+1000+Top": [600, 1200],
    "ACB_OG+Top+1250+Top": [600, 1200],
    "ACB_OG+Top+1600+Top": [600, 1200],
    "ACB_OG+Top+2000+Bottom": [800, 800],
    "ACB_OG+Top+2500+Bottom": [800, 800],
    "ACB_OG+Top+2000+Top": [800, 1200],
    "ACB_OG+Top+2500+Top": [800, 1200],
    "ACB_OG+Top+3200+Bottom": [1000, 1000],
    "ACB_OG+Top+4000+Bottom": [1000, 1000],
    "ACB_OG+Top+3200+Top": [1000, 1400],
    "ACB_OG+Top+4000+Top": [1000, 1400],
    "ACB_BC+Top+800+NA": [800, 800],
    "ACB_BC+Top+1000+NA": [800, 800],
    "ACB_BC+Top+1250+NA": [800, 800],
    "ACB_BC+Top+1600+NA": [800, 800],
    "ACB_BC+Top+2000+NA": [1000, 800],
    "ACB_BC+Top+2500+NA": [1000, 800],
    "ACB_BC+Top+3200+NA": [1400, 1000],
    "ACB_BC+Top+4000+NA": [1400, 1000],
    "ACB_IC+Bottom+800+Bottom": [600, 1200],
    "ACB_IC+Bottom+1000+Bottom": [600, 1200],
    "ACB_IC+Bottom+1250+Bottom": [600, 1200],
    "ACB_IC+Bottom+1600+Bottom": [600, 1200],
    "ACB_IC+Bottom+800+Top": [600, 800],
    "ACB_IC+Bottom+1000+Top": [600, 800],
    "ACB_IC+Bottom+1250+Top": [600, 800],
    "ACB_IC+Bottom+1600+Top": [600, 800],
    "ACB_IC+Bottom+2000+Bottom": [800, 1200],
    "ACB_IC+Bottom+2500+Bottom": [800, 1200],
    "ACB_IC+Bottom+2000+Top": [800, 800],
    "ACB_IC+Bottom+2500+Top": [800, 800],
    "ACB_OG+Bottom+800+Bottom": [600, 1200],
    "ACB_OG+Bottom+1000+Bottom": [600, 1200],
    "ACB_OG+Bottom+1250+Bottom": [600, 1200],
    "ACB_OG+Bottom+1600+Bottom": [600, 1200],
    "ACB_OG+Bottom+800+Top": [600, 800],
    "ACB_OG+Bottom+1000+Top": [600, 800],
    "ACB_OG+Bottom+1250+Top": [600, 800],
    "ACB_OG+Bottom+1600+Top": [600, 800],
    "ACB_OG+Bottom+2000+Bottom": [800, 1200],
    "ACB_OG+Bottom+2500+Bottom": [800, 1200],
    "ACB_OG+Bottom+2000+Top": [800, 800],
    "ACB_OG+Bottom+2500+Top": [800, 800],
    "ACB_BC+Bottom+800+NA": [800, 800],
    "ACB_BC+Bottom+1000+NA": [800, 800],
    "ACB_BC+Bottom+1250+NA": [800, 800],
    "ACB_BC+Bottom+1600+NA": [800, 800],
    "ACB_BC+Bottom+2000+NA": [1000, 800],
    "ACB_BC+Bottom+2500+NA": [1000, 800],
  };
  
  var FR_VIEW_MODEL_NAME__ACB = {
    "ACB_IC+800": ["FV_CBP_0600"],
    "ACB_IC+1000": ["FV_CBP_0600"],
    "ACB_IC+1250": ["FV_CBP_0600"],
    "ACB_IC+1600": ["FV_CBP_0600"],
    "ACB_IC+2000": ["FV_CBP_0800"],
    "ACB_IC+2500": ["FV_CBP_0800"],
    "ACB_IC+3200": ["FV_CBP_1000"],
    "ACB_IC+4000": ["FV_CBP_1000"],
    "ACB_OG+800": ["FV_CBP_0600"],
    "ACB_OG+1000": ["FV_CBP_0600"],
    "ACB_OG+1250": ["FV_CBP_0600"],
    "ACB_OG+1600": ["FV_CBP_0600"],
    "ACB_OG+2000": ["FV_CBP_0800"],
    "ACB_OG+2500": ["FV_CBP_0800"],
    "ACB_OG+3200": ["FV_CBP_1000"],
    "ACB_OG+4000": ["FV_CBP_1000"],
    "ACB_BC+800": ["FV_CBP_BC_0800"],
    "ACB_BC+1000": ["FV_CBP_BC_0800"],
    "ACB_BC+1250": ["FV_CBP_BC_0800"],
    "ACB_BC+1600": ["FV_CBP_BC_0800"],
    "ACB_BC+2000": ["FV_CBP_BC_1000"],
    "ACB_BC+2500": ["FV_CBP_BC_1000"],
    "ACB_BC+3200": ["FV_CBP_BC_1400"],
    "ACB_BC+4000": ["FV_CBP_BC_1400"],
  };
  
  var BOT_VIEW_MODEL_NAME__ACB = {
    "ACB_IC+800+Top+Top": ["BV_CBP_T_0600"],
    "ACB_IC+1000+Top+Top": ["BV_CBP_T_0600"],
    "ACB_IC+1250+Top+Top": ["BV_CBP_T_0600"],
    "ACB_IC+1600+Top+Top": ["BV_CBP_T_0600"],
    "ACB_IC+2000+Top+Top": ["BV_CBP_T_0800"],
    "ACB_IC+2500+Top+Top": ["BV_CBP_T_0800"],
    "ACB_IC+3200+Top+Top": ["BV_CBP_T_1000"],
    "ACB_IC+4000+Top+Top": ["BV_CBP_T_1000"],
    "ACB_IC+800+Top+Bottom": ["BV_CBP_0600"],
    "ACB_IC+1000+Top+Bottom": ["BV_CBP_0600"],
    "ACB_IC+1250+Top+Bottom": ["BV_CBP_0600"],
    "ACB_IC+1600+Top+Bottom": ["BV_CBP_0600"],
    "ACB_IC+2000+Top+Bottom": ["BV_CBP_0800"],
    "ACB_IC+2500+Top+Bottom": ["BV_CBP_0800"],
    "ACB_IC+3200+Top+Bottom": ["BV_CBP_1000"],
    "ACB_IC+4000+Top+Bottom": ["BV_CBP_1000"],
    "ACB_IC+800+Bottom+Top": ["BV_CBP_0600"],
    "ACB_IC+1000+Bottom+Top": ["BV_CBP_0600"],
    "ACB_IC+1250+Bottom+Top": ["BV_CBP_0600"],
    "ACB_IC+1600+Bottom+Top": ["BV_CBP_0600"],
    "ACB_IC+2000+Bottom+Top": ["BV_CBP_0800"],
    "ACB_IC+2500+Bottom+Top": ["BV_CBP_0800"],
    "ACB_IC+800+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_IC+1000+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_IC+1250+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_IC+1600+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_IC+2000+Bottom+Bottom": ["BV_CBP_T_0800"],
    "ACB_IC+2500+Bottom+Bottom": ["BV_CBP_T_0800"],
    "ACB_OG+800+Top+Top": ["BV_CBP_T_0600"],
    "ACB_OG+1000+Top+Top": ["BV_CBP_T_0600"],
    "ACB_OG+1250+Top+Top": ["BV_CBP_T_0600"],
    "ACB_OG+1600+Top+Top": ["BV_CBP_T_0600"],
    "ACB_OG+2000+Top+Top": ["BV_CBP_T_0800"],
    "ACB_OG+2500+Top+Top": ["BV_CBP_T_0800"],
    "ACB_OG+3200+Top+Top": ["BV_CBP_T_1000"],
    "ACB_OG+4000+Top+Top": ["BV_CBP_T_1000"],
    "ACB_OG+800+Top+Bottom": ["BV_CBP_0600"],
    "ACB_OG+1000+Top+Bottom": ["BV_CBP_0600"],
    "ACB_OG+1250+Top+Bottom": ["BV_CBP_0600"],
    "ACB_OG+1600+Top+Bottom": ["BV_CBP_0600"],
    "ACB_OG+2000+Top+Bottom": ["BV_CBP_0800"],
    "ACB_OG+2500+Top+Bottom": ["BV_CBP_0800"],
    "ACB_OG+3200+Top+Bottom": ["BV_CBP_1000"],
    "ACB_OG+4000+Top+Bottom": ["BV_CBP_1000"],
    "ACB_OG+800+Bottom+Top": ["BV_CBP_0600"],
    "ACB_OG+1000+Bottom+Top": ["BV_CBP_0600"],
    "ACB_OG+1250+Bottom+Top": ["BV_CBP_0600"],
    "ACB_OG+1600+Bottom+Top": ["BV_CBP_0600"],
    "ACB_OG+2000+Bottom+Top": ["BV_CBP_0800"],
    "ACB_OG+2500+Bottom+Top": ["BV_CBP_0800"],
    "ACB_OG+800+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_OG+1000+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_OG+1250+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_OG+1600+Bottom+Bottom": ["BV_CBP_T_0600"],
    "ACB_OG+2000+Bottom+Bottom": ["BV_CBP_T_0800"],
    "ACB_OG+2500+Bottom+Bottom": ["BV_CBP_T_0800"],
    "ACB_BC+800+Top+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+1000+Top+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+1250+Top+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+1600+Top+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+2000+Top+NA": ["BV_CBP_BC_1000"],
    "ACB_BC+2500+Top+NA": ["BV_CBP_BC_1000"],
    "ACB_BC+3200+Top+NA": ["BV_CBP_BC_1400"],
    "ACB_BC+4000+Top+NA": ["BV_CBP_BC_1400"],
    "ACB_BC+800+Bottom+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+1000+Bottom+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+1250+Bottom+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+1600+Bottom+NA": ["BV_CBP_BC_0800"],
    "ACB_BC+2000+Bottom+NA": ["BV_CBP_BC_1000"],
    "ACB_BC+2500+Bottom+NA": ["BV_CBP_BC_1000"],
  };
  
  var CUBICLE_TERM_LOC__MCCB = {
    "OFP_PANEL_FCA+IC-T & OG-T": ["Top"],
    "OFP_PANEL_FCA+IC-T & OG-B": ["Top"],
    "OFP_PANEL_FCA+IC-B & OG-B": ["Bottom"],
    "OFP_PANEL_FCA+IC-B & OG-T": ["Bottom"],
    "OFP_PANEL_RCA+IC-T & OG-T": ["Top"],
    "OFP_PANEL_RCA+IC-T & OG-B": ["Bottom"],
    "OFP_PANEL_RCA+IC-B & OG-B": ["Bottom"],
    "OFP_PANEL_RCA+IC-B & OG-T": ["Top"],
    "MCCB_FULL_PANEL+IC-T & OG-T": ["Top"],
    "MCCB_FULL_PANEL+IC-T & OG-B": ["Top"],
    "MCCB_FULL_PANEL+IC-B & OG-B": ["Bottom"],
    "MCCB_FULL_PANEL+IC-B & OG-T": ["Bottom"],
    "EMPTY+IC-T & OG-T": ["Top"],
    "EMPTY+IC-T & OG-B": ["Top"],
    "EMPTY+IC-B & OG-B": ["Bottom"],
    "EMPTY+IC-B & OG-T": ["Bottom"],
  };
  
  var CUBICLE_WIDTH__FR_VIEW_MODEL_NAME__MCCB__OFP = {
    "OFP_PANEL_FCA+SINGLE_PANEL": [1200, "FV_OFP_FCA_SP_1200"],
    "OFP_PANEL_FCA+DOUBLE_PANEL": [2200, "FV_OFP_FCA_DP_2200"],
    "OFP_PANEL_RCA+SINGLE_PANEL": [800, "FV_OFP_RCA_SP_0800"],
    "OFP_PANEL_RCA+DOUBLE_PANEL": [1400, "FV_OFP_RCA_DP_1400"],
  };
  
  var CUBICLE_DEPTH__BOT_VIEW_MODEL_NAME__MCCB = {
    "OFP_PANEL_FCA+SINGLE_PANEL+Top+Top": [1000, "BV_OFP_FCA_SP_SF_T_1200"],
    "OFP_PANEL_FCA+SINGLE_PANEL+Top+Bottom": [800, "BV_OFP_FCA_SP_SF_1200"],
    "OFP_PANEL_FCA+SINGLE_PANEL+Bottom+Top": [800, "BV_OFP_FCA_SP_SF_1200"],
    "OFP_PANEL_FCA+SINGLE_PANEL+Bottom+Bottom": [1000, "BV_OFP_FCA_SP_SF_T_1200"],
    "OFP_PANEL_FCA+DOUBLE PANEL+Top+Top": [1000, "BV_OFP_FCA_DP_SF_T_2200"],
    "OFP_PANEL_FCA+DOUBLE PANEL+Top+Bottom": [800, "BV_OFP_FCA_DP_SF_2200"],
    "OFP_PANEL_FCA+DOUBLE PANEL+Bottom+Top": [800, "BV_OFP_FCA_DP_SF_2200"],
    "OFP_PANEL_FCA+DOUBLE PANEL+Bottom+Bottom": [1000, "BV_OFP_FCA_DP_SF_T_2200"],
    "OFP_PANEL_RCA+SINGLE_PANEL+Top+Top": [1000, "BV_OFP_RCA_SP_T_0800"],
    "OFP_PANEL_RCA+SINGLE_PANEL+Top+Bottom": [800, "BV_OFP_RCA_SP_0800"],
    "OFP_PANEL_RCA+SINGLE_PANEL+Bottom+Top": [800, "BV_OFP_RCA_SP_0800"],
    "OFP_PANEL_RCA+SINGLE_PANEL+Bottom+Bottom": [1000, "BV_OFP_RCA_SP_T_0800"],
    "OFP_PANEL_RCA+DOUBLE PANEL+Top+Top": [1000, "BV_OFP_RCA_DP_T_1400"],
    "OFP_PANEL_RCA+DOUBLE PANEL+Top+Bottom": [800, "BV_OFP_RCA_DP_1400"],
    "OFP_PANEL_RCA+DOUBLE PANEL+Bottom+Top": [800, "BV_OFP_RCA_DP_1400"],
    "OFP_PANEL_RCA+DOUBLE PANEL+Bottom+Bottom": [1000, "BV_OFP_RCA_DP_T_1400"],
  };
  
  var OFP_CUBICLE_DESIGN__MCCB = {
    1: ["SINGLE_PANEL"],
    2: ["DOUBLE_PANEL"],
  };
  
  var CUBICLE_WIDTH__FR_VIEW_MODEL_NAME__MCCB = {
    1: [400, "FV_MCCB_SP_SF_0400"],
    2: [800, "FV_MCCB_DP_SF_0800"],
    3: [1200, "FV_MCCB_TP_SF_1200"],
  };
  
  var CUBICLE_DEPTH__BOT_VIEW_MODEL_NAME__MCCB = {
    "1+Top+Top": [1000, "BV_MCCB_SP_SF_T_0400"],
    "1+Top+Bottom": [800, "BV_MCCB_SP_SF_0400"],
    "1+Bottom+Top": [800, "BV_MCCB_SP_SF_0400"],
    "1+Bottom+Bottom": [1000, "BV_MCCB_SP_SF_T_0400"],
    "2+Top+Top": [1000, "BV_MCCB_DP_SF_T_0800"],
    "2+Top+Bottom": [800, "BV_MCCB_DP_SF_0800"],
    "2+Bottom+Top": [800, "BV_MCCB_DP_SF_0800"],
    "2+Bottom+Bottom": [1000, "BV_MCCB_DP_SF_T_0800"],
    "3+Top+Top": [1000, "BV_MCCB_TP_SF_T_1200"],
    "3+Top+Bottom": [800, "BV_MCCB_TP_SF_1200"],
    "3+Bottom+Top": [800, "BV_MCCB_TP_SF_1200"],
    "3+Bottom+Bottom": [1000, "BV_MCCB_TP_SF_T_1200"],
  };
  
  var FR_VIEW_MODEL_NAME__EMPTY = {
    400: ["FV_FP_SF_0400"],
    600: ["FV_FP_SF_0600"],
    800: ["FV_FP_SF_0800"],
    1000: ["FV_FP_SF_1000"],
  };
  
  var CUBICLE_DEPTH__BOT_VIEW_MODEL_NAME__EMPTY = {
    "Top+Top": [1000, "BV_FP_SF_0400"],
    "Top+Bottom": [800, "BV_FP_SF_0600"],
    "Bottom+Top": [800, "BV_FP_SF_0800"],
    "Bottom+Bottom": [1000, "BV_FP_SF_1000"],
  };
  