/**
 * Name: D3 Drawing APIs
 * Type: Non Editable Library
 * Author: VIPIN VISWAM
 * Email: vipin.viswam@siemens.com
 * Created on : 16/11/2017
 * License to: Siemens Technologies Pvt. Ltd.
 * STS EM LP COMPAS
 */


var d3Valid = {
    num: function (a) {
      return (a !== null && a !== undefined && !isNaN(a)) ? true : false;
    },
    str: function (a) {
      return (a !== null && a !== undefined) ? true : false;
    }
  };
  
  
  var d3graphModel = {
    model: this.model || {},
    createModel: function (container) {
      this.model[container] = {};
      var model = new d3GraphModel();
      this.model[container] = model;
      return model;
    },
    getModel: function (container) {
      return this.model[container];
    },
    clearModel: function (container) {
      var model = this.model[container];
      model.svgElements = [];
      model.selectable = {};
      model.draggable = {};
      model.droppable = {};
      model.vertices = {};
      model.verticesArray = [];
      model.d3Window = {};
      model.links = {};
      model.data = {};
      model.treeLayouts = {};
      model.grid = {};
      model.activeGridLines = [];
      model.dimensions = {};
      return model;
    },
    emptyModel: function (container) {
      if (container) {
        this.model[container] = {};
        return this.model[container];
      } else {
        this.model = {};
        return this.model;
      }
  
    }
  };
  
  
  
  class d3GraphModel {
    constructor() {
      this.svgElements = [];
      this.selectable = {};
      this.draggable = {};
      this.droppable = {};
      this.vertices = {};
      this.verticesArray = [];
      this.d3Window = {};
      this.links = {};
      this.data = {};
      this.treeLayouts = {};
      this.grid = {};
      this.activeGridLines = [];
      this.dimensions = {};
      return this;
    }
  }
  
  
  /*
   * Class: d3Graph
   *
   * Create a drwaing and appending svg to give element usind d3.select. and set the width and Height to the svg
   * Create New Graph and set the parameter of Selector, width, Height
   *
   * eg: new d3Graph(selector, width, height);
   */
  
  class d3Graph {
    constructor(container, element, width, height) {
      var svg = d3.select(element).html("").append("svg").attr("id", "GA_Diagram"), containerBox = d3.select(element).node().getBoundingClientRect(), containerWidth = containerBox.width, containerHeight = containerBox.height, width = d3Valid.str(width) ? width : containerWidth + 'px', height = d3Valid.str(height) ? height : containerHeight + 'px';
      console.log(height);
      svg.attr("width", width);
      svg.attr("height", height);
      if ("ontouchstart" in document.documentElement) {
        svg.attr('class', 'd3touch');
      }
      var node = svg.append('g').attr("id", "GA_Diagram_Group"), dragAssist = node.append('g').attr('id', 'dragAssist').attr('transform', 'translate(0, 0)');
  
      d3graphModel.createModel(container);
      var geo = {
        x: 0,
        y: 0,
        w: width,
        h: height
      };
      this.geo = geo;
      this.model = d3graphModel.getModel(container);
      this.children = {};
      this.width = width;
      this.height = height;
      this.container = container;
      this.node = node;
      this.dragAssist = dragAssist;
      this.svg = svg;
      this.model.d3Graph = this;
      this.zoomEnabled = false;
      this.type = 'svg';
      this.touchDevice = "ontouchstart" in document.documentElement ? true : false;
      return this;
    }
    getModel(container) {
      return d3graphModel.getModel(container);
    }
    clearModel(container) {
      return d3graphModel.clearModel(container);
    }
    emptyModel(container) {
      return d3graphModel.emptyModel(container);
    }
    getSVG() {
      return this.svg;
    }
    setBackground(value) {
      console.log(this);
      var svg = this.svg;
      svg.style('background', value);
      return this;
    }
    getMouse() {
      var x = d3.event.sourceEvent.clientX, y = d3.event.sourceEvent.clientY;
      return [x, y];
    }
    getBBox() {
      return this.svg._groups[0][0].getBoundingClientRect();
    }
    getAllMouseover(cell) {
      var mouse = this.getMouse(), overItems = [];
      model = this.model;
      for (var i in model.vertices) {
        var item = model.vertices[i];
  
        if (item.shape) {
          var geo = item.getBBox(), geoX = geo.x, geoY = geo.y, geoW = geo.width, geoH = geo.height, mouseX = mouse[0], mouseY = mouse[1];
  
          if (mouseX > geoX && mouseX < (geoX + geoW) && mouseY > geoY && mouseY < (geoY + geoH)) {
            if (cell.shape !== item.shape)
              overItems.push(item);
          }
        }
  
      }
      return overItems;
    }
    getAttrBox(cell) {
      var x = parseFloat(cell.shape.attr('x')), y = parseFloat(cell.shape.attr('y')), w = parseFloat(cell.shape.attr('width')), h = parseFloat(cell.shape.attr('height'));
  
      var attrBox = {
        x: x,
        y: y,
        width: w,
        height: h
      };
  
      return attrBox;
    }
    getOnMouseover(cell) {
      var allMouseOver = this.getAllMouseover(cell);
      var overItem;
      for (var i in allMouseOver) {
        overItem = allMouseOver[i];
      }
      return overItem;
    }
    clearSVG() {
      d3graphModel.clearModel();
      this.node.selectAll("*").remove();
      return this;
    }
    /*
     * Function: zoomable
     *
     * Set Zoom and Pan functions
     * Create New drawing and set the parameter of Selector, width, Height
     *
     */
    zoomable(value, scaleExtend, target, dblclickZoom) {
      var item = this, svg = this.svg, node, model = this.model, scale;
      if (target) {
        node = target.node;
      } else {
        node = this.node;
      }
  
      var zoomable = {
        enabled: true,
        scaleExtend: scaleExtend,
        target: target,
        scale: 1,
        translate: [0, 0]
      };
  
      var zoom = d3.zoom()
        .scaleExtent(scaleExtend)
        .on("zoom", function () {
          node.attr("transform", d3.event.transform);
          item.dragAssist.attr("transform", 'scale(' + d3.event.transform.k + ')');
          zoomable.scale = d3.event.transform.k;
          var translate = [d3.event.transform.x, d3.event.transform.y];
          zoomable.translate = translate;
        })
        .on("end", function () {
        });
  
      if (value) {
        svg.call(zoom);
        model.zoomEnabled = true;
      } else {
        svg.on('.zoom', null);
        model.zoomEnabled = true;
      }
      if (!dblclickZoom) {
        svg.on("dblclick.zoom", null);
      }
      zoomable.zoom = zoom;
      model.zoomable = zoomable;
  
      this.zoomable = zoomable;
  
      return this;
    }
    enableZoom(value) {
      var item = this, svg = this.svg, model = this.model, zoom = item.zoomable.zoom;
  
      if (value) {
        svg.call(zoom);
        model.zoomEnabled = true;
      } else {
        svg.on('.zoom', null);
        model.zoomEnabled = true;
      }
  
    }
    disableDblclickZoom() {
    }
    selectMode(mode) {
      var model = this.model;
      model.selectMode = mode;
      return this;
    }
    //SBD.d3Window(id, x, y, width, height, style);
    d3Window(id, x, y, width, height, style) {
      var model = this.model;
      var d3WindowCell = {};
      d3WindowCell.id = id;
      d3WindowCell.geo = {
        x: x,
        y: y,
        w: width,
        h: height
      };
      d3WindowCell.style = style;
      var boxStyle = {};
      boxStyle.fill = style.fill;
      boxStyle.stroke = style.stroke;
      boxStyle.strokeWidth = style.strokeWidth;
  
      var headStyle = {};
      headStyle.fill = style.headerColor;
      headStyle.stroke = style.headerStroke;
      headStyle.strokeWidth = style.headerStrokeWidth;
  
      var headerHeight = style.headerHeight ? style.headerHeight : 30;
  
  
      var d3window;
  
  
      if (style.slideOverlay) {
        style.floating = false;
        style.resizable = false;
        style.minmize = false;
        var svgBBox = model.d3Graph.getBBox();
        if (style.slideOverlay === 'left') {
          d3window = this.insertVertex('rectangle', 'window-' + id, 0, 0, width, svgBBox.height, boxStyle, true);
          d3window.node.transition().attr("transform", "translate(" + -width + ", 0)");
        } else if (style.slideOverlay === 'right') {
          d3window = this.insertVertex('rectangle', 'window-' + id, (svgBBox.width - width), 0, width, svgBBox.height, boxStyle, true);
          d3window.node.transition().attr("transform", "translate(" + svgBBox.width + ", 0)");
          window.addEventListener("resize", function () {
            var svgRBBox = model.d3Graph.getBBox();
            d3window.node.transition().attr("transform", "translate(" + svgRBBox.width + ", 0)");
          });
        }
      } else {
        d3window = this.insertVertex('rectangle', 'window-' + id, x, y, width, height, boxStyle, true);
      }
  
      d3window.type = 'window';
  
  
  
      var header = d3window.insertVertex('rectangle', 'windowHeader-' + id, 0, 0, width, headerHeight, headStyle, true);
      header.type = 'windowHeader';
  
      var by = y + headerHeight, bh = height - headerHeight, body;
  
      if (style.bodyType === 'svg') {
        body = d3window.insertVertex('group', id, x, by, width, bh, null, true);
      } else {
        body = d3window.insertVertex('foreignObject', id, x, by, width, bh, null, true);
      }
  
      body.type = 'windowBody';
  
      if (style.headerText)
        header.addLabel(style.headerText, style.headerTextStyle);
  
      if (model.d3Graph.touchDevice) {
        body.node.on('touchmove', function () {
          model.d3Graph.enableZoom(false);
        });
        body.node.on('touchend', function () {
          model.d3Graph.enableZoom(true);
        });
  
      } else {
        d3window.onHover(function () {
          model.d3Graph.enableZoom(false);
        }, function () {
          model.d3Graph.enableZoom(true);
        });
  
      }
  
  
  
      if (style.floating) {
        d3window.draggable(true, null, null, header);
      }
  
      var minimized = false;
  
      if (style.minmizable) {
        header.addBadge('image', 'minimizeButton', 'Minimize', -5, 0, 20, 20, style.buttonStyle, style.buttonStyle.textStyle, function (cell) {
          d3.event.stopPropagation();
          if (!minimized) {
            d3window.shape.style('display', 'none');
            body.shape.style('display', 'none');
            cell.badges.minimizeButton.shape.shape.attr('xlink:href', style.maximizeIcon);
            cell.badges.minimizeButton.title.node.text('Maximize');
            minimized = true;
          } else {
            d3window.shape.style('display', 'block');
            body.shape.style('display', 'block');
            cell.badges.minimizeButton.shape.shape.attr('xlink:href', style.minimizeIcon);
            cell.badges.minimizeButton.title.node.text('Minimize');
            minimized = false;
          }
  
        }, style.minimizeIcon);
      }
  
  
      if (style.close) {
        header.addBadge('image', 'closeButton', 'Close', -5, 0, 20, 20, style.buttonStyle, style.buttonStyle.textStyle, function (cell) {
          d3.event.stopPropagation();
          body.close();
  
        }, style.closeIcon);
      }
      body.window = d3window;
      body.header = header;
      header.window = d3window;
      header.body = body;
      d3WindowCell.window = d3window;
      d3WindowCell.header = header;
      d3WindowCell.body = body;
      d3WindowCell.open = true;
      model.d3Window[id] = d3WindowCell;
  
      if (style.defaultView) {
        if (style.defaultView === 'open') {
          body.open();
        } else if (style.defaultView === 'close') {
          body.close();
        }
      } else {
        body.close();
      }
  
      return body;
    }
    insertVertex(type, id, x, y, w, h, style, relative, utility) {
      return new d3Vertex(this, type, id, x, y, w, h, style, relative, utility);
    }
    insertLink(id, source, target, type, style) {
      return new d3Link(id, this, source, target, type, style);
    }
    /*--------------------- BAR CHART ------------------*/
    barChart(data, style, actions) {
      console.log(data);
      var chart = this.node;
      var svgBBox = this.getBBox(), margin = style.margin;
  
      // Define the dimensions
      var dimensions = {
        gMargin: style.margin,
        gInnerWidth: svgBBox.width - (style.margin * 2),
        gInnerHeight: svgBBox.height - (style.margin * 2),
        bMargin: style.space
      };
  
      var width = +svgBBox.width - margin.left - margin.right, height = +svgBBox.height - margin.top - margin.bottom;
  
      var maxValue = d3.max(data, function (d) {
        return parseFloat(d.value);
      });
  
  
      if (style.tooltip) {
        var tooltip = d3.select("body").append("div").attr("class", "chartTooltip");
      }
  
      var g = chart.append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");
  
      var bars = g.selectAll(".bar")
        .data(data)
        .enter();
  
      var barShape, barValue;
  
      var xTicks = [];
      for (var i = 0; i < data.length; i++) {
        xTicks.push(data.label); // 0.5 is to ensure the ticks are offset correctly to match the data
      }
  
  
      if (style.type === 'horizontal') {
        var x = d3.scaleLinear().range([0, width]);
        var y = d3.scaleBand().range([height, 0]);
  
        x.domain([0, maxValue]);
  
        y.domain(data.map(function (d, i) {
          return i;
        })).padding(style.space);
  
        g.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x)
            .ticks(style.tickCount)
            .tickFormat(function (d, i) {
              return parseInt(d);
            }).tickSizeInner([-height]));
  
        g.append("text")
          .attr("text-anchor", "middle")
          .attr("x", width / 2)
          .attr("y", height + margin.bottom) // this makes it easy to centre the text as the transform is applied to the anchor
          .style("font-size", "14")
          .style("font-weight", "700")
          .text("Price in % of Total Price");
  
  
        g.append("g")
          .attr("class", "y axis")
          .call(d3.axisLeft(y)
            .tickFormat(function (d, i) {
              return data[i].label;
            }));
  
        g.append("text")
          .attr("transform", "rotate(-90)")
          .attr("y", 0 - margin.left)
          .attr("x", 0 - (height / 2))
          .attr("dy", "2em")
          .style("text-anchor", "middle")
          .style("font-size", "14")
          .style("font-weight", "700")
          .text("Components");
  
        barShape = bars.append("rect").attr("class", "bar-chart")
          .attr("x", 0)
          .attr("height", y.bandwidth())
          .attr("y", function (d, i) {
            return y(d.id);
          })
          .attr("width", function (d) {
            return x(parseFloat(d.value));
          });
  
        if (style.showValue) {
          //add a value label to the right of each bar
          barValue = bars.append("text")
            .attr("class", "chart-label")
            //y position of the label is halfway down the bar
            .attr("y", function (d) {
              return (y(d.id) + y.bandwidth() / 2) + 4;
            })
            //x position is 3 pixels to the right of the bar
            .attr("x", function (d) {
              return x(parseFloat(d.value)) + 10;
            })
            .text(function (d) {
              var value;
              if (style.valuePrefix) {
                value = style.prefixPosition && style.prefixPosition === 'after' ? d.value + style.valuePrefix : style.valuePrefix + d.value;
              } else {
                value = d.value;
              }
              return value;
            });
        }
  
  
  
  
      } else {
  
        var x = d3.scaleBand().range([0, width]);
        var y = d3.scaleLinear().range([height, 0]);
  
        y.domain([0, maxValue]);
  
        x.domain(data.map(function (d, i) {
          return i;
        })).padding(style.space);
  
        g.append("g")
          .attr("class", "x axis")
          .attr("transform", "translate(0," + height + ")")
          .call(d3.axisBottom(x)
            .tickFormat(function (d, i) {
              return data[i].label;
            }));
  
        g.append("g")
          .attr("class", "y axis")
          .call(d3.axisLeft(y)
            .ticks(style.tickCount)
            .tickFormat(function (d, i) {
              return parseInt(d);
            }).tickSizeInner([-width]));
  
  
        barShape = bars.append("rect").attr("class", "bar-chart")
          .attr("y", function (d) {
            return y(parseFloat(d.value));
          })
          .attr("height", function (d) {
            return height - y(parseFloat(d.value));
          })
          .attr("x", function (d) {
            return x(d.id);
          })
          .attr("width", x.bandwidth());
  
        if (style.showValue) {
          //add a value label to the right of each bar
          barValue = bars.append("text")
            .attr("class", "chart-label")
            //y position of the label is halfway down the bar
            .attr("x", function (d) {
              return (x(d.id) + x.bandwidth() / 2) - 8;
            })
            //x position is 3 pixels to the right of the bar
            .attr("y", function (d) {
              return y(parseFloat(d.value)) - 10;
            })
            .text(function (d) {
              return d.value;
            });
        }
      }
  
  
  
      barShape.on("mousemove", function (d) {
        var toolTipText = d.tooltip ? d.tooltip : (d.label) + "<br>" + (parseFloat(d.value));
        tooltip
          .style("left", d3.event.pageX - 50 + "px")
          .style("top", d3.event.pageY - 70 + "px")
          .style("display", "inline-block")
          .html(toolTipText);
      })
        .on("mouseout", function (d) {
          tooltip.style("display", "none");
        });
  
      var shapeColor = style.baseColor ? style.baseColor : '#999999';
  
      barShape.attr('fill', function (d, i) {
        return d.color ? d.color : shapeColor;
      });
  
      style.textColor && barValue.attr('fill', style.textColor);
      style.textSize && barValue.attr('font-size', style.textSize);
      style.textBold && barValue.attr('font-weight', 'bold');
  
  
      !style.tickline && g.selectAll(".tick line").remove();
      style.ticklineColor && g.selectAll(".tick line").attr("stroke", style.ticklineColor);
      style.ticklineDashed && g.selectAll(".tick line").attr("stroke-dasharray", "2,2");
  
      style.tickTextColor && g.selectAll(".tick text").attr("fill", style.tickTextColor);
      style.tickTextSize && g.selectAll(".tick text").attr("font-size", style.tickTextSize);
      style.tickTextBold && g.selectAll(".tick text").attr("font-weight", 'bold');
  
      barShape.on('click', function (event) {
        actions.onClick(d3.select(this), event);
      });
  
      barShape.on('onHover', function () {
        actions.onHover(d3.select(this), event);
      });
  
      barShape.on('onBlur', function () {
        actions.onBlur(d3.select(this), event);
      });
  
  
  
  
  
  
  
  
  
      //    // Define the scales
      //    var xScale = d3.scaleLinear()
      //        .domain([0, data.length])
      //        .range([0, dimensions.gInnerWidth]);
      //
      //    // Get the max value for the data. This will determine how high our y-scale is
      //    var maxValue = d3.max(data, function (d) {
      //        return d.value;
      //    });
      //
      //    // Now define the yScale, or vertical scale
      //    var yScale = d3.scaleLinear()
      //        .domain([0, maxValue])
      //        .range([0, dimensions.gInnerHeight]);
      //
      //    // Finally, define the yAxis scale. This is identical to the yScale except that the domain is inverted. This is because the scale is determined from top down, rather than bottom up, and the data would look upside down otherwise.
      //    var yAxisScale = d3.scaleLinear()
      //        .domain([100, 0])
      //        .range([0, dimensions.gInnerHeight]);
      //
      //    // Render the chart
      //    // Select the containing element and append an SVG with your defined width and height
      //    var chart = this.node;
      //
      //    // Render the y-axis
      //    var yAxis = d3.axisLeft(yAxisScale)
      //        // This is to make the horizontal tick lines stretch all the way across the chart
      //        .tickSizeInner(-dimensions.gInnerWidth)
      //        // This spaces the tick values slights from the axis
      //        .tickPadding(10);
      //
      //    var yAxisNode = chart.append('g')
      //        .attr('class', 'axis axis-y')
      //        .attr('transform', 'translate(' + dimensions.gMargin + ', ' + dimensions.gMargin + ')')
      //        .call(yAxis);
      //
      //    !style.tickline && yAxisNode.selectAll(".tick line").remove();
      //    style.ticklineColor && yAxisNode.selectAll(".tick line").attr("stroke", style.ticklineColor);
      //    style.ticklineDashed && yAxisNode.selectAll(".tick line").attr("stroke-dasharray", "2,2");
      //
      //    style.tickTextColor && yAxisNode.selectAll(".tick text").attr("fill", style.tickTextColor);
      //    style.tickTextSize && yAxisNode.selectAll(".tick text").attr("font-size", style.tickTextSize);
      //    style.tickTextBold && yAxisNode.selectAll(".tick text").attr("font-weight", 'bold');
      //
      //
      //
      //    // Define the ticks for the xAxis
      //    var xTicks = []
      //    for (var i = 0; i < data.length; i++) {
      //        xTicks.push(i + 0.5); // 0.5 is to ensure the ticks are offset correctly to match the data
      //    }
      //    // Render the x-axis
      //    var xAxis = d3.axisBottom(xScale)
      //        .tickValues(xTicks)
      //        .tickFormat(function (d, i) {
      //            return data[i].label;
      //        });
      //
      //    var xAxisNode = chart.append('g')
      //        .attr('class', 'axis axis-x')
      //        .attr('transform', 'translate(' + dimensions.gMargin + ', ' + (dimensions.gMargin + dimensions.gInnerHeight) + ')')
      //        .call(xAxis);
      //
      //    style.tickTextColor && xAxisNode.selectAll(".tick text").attr("fill", style.tickTextColor);
      //    style.tickTextSize && xAxisNode.selectAll(".tick text").attr("font-size", style.tickTextSize);
      //    style.tickTextBold && xAxisNode.selectAll(".tick text").attr("font-weight", 'bold');
      //
      //
      //    // Render the bars
      //    // This is rendered last so the bars appear on top of the axis and not vice versa
      //    var barAttributes = {
      //        class: 'bar-line',
      //        transform: 'translate(' + dimensions.gMargin + ', ' + dimensions.gMargin + ')',
      //        height: function (d, i) {
      //            return yScale(d.value);
      //        },
      //        width: (dimensions.gInnerWidth / data.length) - (dimensions.bMargin * 2),
      //        x: function (d, i) {
      //            return (dimensions.gInnerWidth / data.length) * i + dimensions.bMargin;
      //        },
      //        y: function (d, i) {
      //            return dimensions.gInnerHeight - yScale(d.value);
      //        },
      //        fill: function (d, i) {
      //            return d.color ? d.color : style.baseColor;
      //        }
      //    };
      //
      //    var textAttributes = {
      //        class: 'bar-text',
      //        transform: 'translate(' + dimensions.gMargin + ', ' + dimensions.gMargin + ')',
      //        height: function (d, i) {
      //            return yScale(d.value);
      //        },
      //        width: (dimensions.gInnerWidth / data.length) - (dimensions.bMargin * 2),
      //        x: function (d, i) {
      //            var textBBox = this.getBoundingClientRect();
      //            return ((dimensions.gInnerWidth / data.length) * i + dimensions.bMargin) + (((dimensions.gInnerWidth / data.length) - (dimensions.bMargin * 2)) / 2) - (textBBox.width / 2);
      //        },
      //        y: function (d, i) {
      //            return style.valuePosition ? style.valuePosition === 'outside' ? dimensions.gInnerHeight - yScale(d.value) - 5 : dimensions.gInnerHeight - yScale(d.value) + 20 : dimensions.gInnerHeight - yScale(d.value) - 5;
      //        },
      //        fill: function (d, i) {
      //            return style.textColor;
      //        }
      //    };
      //
      //    var barChart = chart.append('g')
      //        .selectAll('.' + barAttributes.class)
      //        .data(data)
      //        .enter()
      //        .append('rect');
      //
      //    if (style.title) {
      //        barChart.append('title').text(function (d, i) {
      //            return d.title ? d.title : d.value;
      //        });
      //    }
      //
      //    if (style.showValue) {
      //        var barChartText = chart.append('g')
      //            .selectAll('.' + textAttributes.class)
      //            .data(data)
      //            .enter()
      //            .append('text')
      //            .text(function (d, i) {
      //                return d.value;
      //            });
      //
      //        for (var i in barAttributes) {
      //            barChartText.attr(i, textAttributes[i]);
      //        }
      //
      //        style.textBold && barChartText.attr("font-weight", 'bold');
      //    }
      //
      //    for (var i in barAttributes) {
      //        barChart.attr(i, barAttributes[i]);
      //    }
      //
      //    barChart.on('click', function (event) {
      //        actions.onClick(d3.select(this), event);
      //    });
      //
      //    barChart.on('onHover', function () {
      //        actions.onHover(d3.select(this), event);
      //    });
      //
      //    barChart.on('onBlur', function () {
      //        actions.onBlur(d3.select(this), event);
      //    });
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  class d3Vertex {
    constructor(parent, type, id, x, y, w, h, style, relative, utility) {
      var node, sx, sy, shape, style;
      if (type === 'foreignObject') {
        shape = parent.node.append('foreignObject').attr('transform', 'translate(' + 0 + ', ' + 0 + ')');
        d3Valid.str(id) && shape.attr('id', id);
        d3Valid.num(w) && shape.attr('width', w);
        d3Valid.num(h) && shape.attr('height', h);
        d3Valid.num(x) && shape.attr('x', x);
        d3Valid.num(y) && shape.attr('y', y);
  
        node = shape.append('xhtml:div').attr('xmlns', 'http://www.w3.org/1999/xhtml');
        d3Valid.str(id) && node.attr('id', 'foreignElement-' + id);
        node.attr('class', 'foreignElement');
        d3Valid.num(w) && node.style('width', w + 'px');
        d3Valid.num(h) && node.style('height', h + 'px');
        node.style('overflow', 'auto');
  
        sx = x;
        sy = y;
  
      } else if (type === 'div') {
        node = parent.node.append('div');
        d3Valid.str(id) && node.attr('id', id);
        d3Valid.num(x) && node.attr('x', x);
        d3Valid.num(y) && node.attr('y', y);
        shape = node;
        sx = x;
        sy = y;
      } else if (type === 'defs') {
        node = parent.node.append('defs');
        d3Valid.str(id) && node.attr('id', id);
        shape = node;
        sx = x;
        sy = y;
      } else if (type === 'symbol') {
        node = parent.node.append('symbol');
        d3Valid.str(id) && node.attr('id', id);
        shape = node;
        sx = x;
        sy = y;
      } else {
        node = parent.node.append('g').attr('transform', 'translate(' + 0 + ', ' + 0 + ')');
        d3Valid.str(id) && node.attr('id', id);
        style = style;
        if (relative) {
          var parentGeo = parent && parent.geo;
          sx = x + parentGeo.x;
          sy = y + parentGeo.y;
        } else {
          sx = x;
          sy = y;
        }
  
        switch (type) {
          case 'rectangle':
            shape = d3Shape.rectangle(node, sx, sy, w, h);
            break;
  
          case 'image':
            shape = d3Shape.image(node, sx, sy, w, h, utility);
            break;
        }
  
        if (style && type !== 'group') {
          for (var index in style) {
            var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
            shape.attr(styleName, style[index]);
  
          }
          if (style.borderRadius) {
            d3Valid.num(style.borderRadius) && shape.attr('rx', style.borderRadius).attr('ry', style.borderRadius);
          }
        }
      }
  
      node.classed('d3Vertex', true);
  
  
  
      var geo = {
        x: sx,
        y: sy,
        w: w,
        h: h
      };
  
      this.relative = relative;
      this.geo = geo;
      this.id = id;
      this.type = type;
      this.node = node;
      this.style = style;
      this.shape = shape;
      this.parent = parent;
      this.links = {};
      this.children = {};
      this.nodeElements = [];
      this.badges = {};
      this.contextMenus = {};
      this.nodeElements.push(shape);
      this.selected = false;
      this.selectionEnabled = false;
      this.dragEnabled = false;
      this.dragging = false;
      this.snappedTo;
      this.model = parent.model;
      this.model.svgElements.push(shape);
      this.model.vertices[id] = this;
      this.model.verticesArray.push(this);
      parent.children[id] = this;
  
      return this;
    }
    insertVertex(type, id, x, y, w, h, style, relative, utility) {
      return new d3Vertex(this, type, id, x, y, w, h, style, relative, utility);
    }
    addShape(type, geometry, style, utility) {
      var item = this, parent = item.parent, model = this.model;
      var node = item.node.append('g'), shape, gx = geometry.p ? Array.isArray(geometry.p[0]) ? geometry.p[0][0] : geometry.p[0] : geometry.x, gy = geometry.p ? Array.isArray(geometry.p[1]) ? geometry.p[0][1] : geometry.p[1] : geometry.y, nx = gx + item.geo.x, ny = gy;
  
      switch (type) {
        case 'rectangle':
          shape = d3Shape.rectangle(node, nx, ny, geometry.w, geometry.h);
          break;
        case 'circle':
          shape = d3Shape.circle(node, nx, ny, geometry.r);
          break;
        case 'ellipse':
          shape = d3Shape.ellipse(node, nx, ny, geometry.w, geometry.h);
          break;
        case 'image':
          shape = d3Shape.image(node, nx, ny, geometry.w, geometry.h, geometry.u);
          break;
        case 'point':
          shape = d3Shape.circle(node, nx, ny, geometry.r);
          break;
        case 'line':
          shape = d3Shape.line(node, geometry.p);
          break;
        case 'arc':
          shape = d3Shape.arc(node, geometry.p);
          break;
        case 'text':
          shape = d3Shape.text(node, nx, ny, geometry.t);
          break;
      }
  
  
  
  
  
  
      var nodeBBox = item.getBBox(shape);
      var scaleY;
  
  
      if (style.layoutOrientation && style.layoutOrientation[1] === 1) {
        switch (type) {
          case 'rectangle':
            scaleY = ((item.geo.h + item.geo.y) - (ny + geometry.h));
            d3Valid.num(scaleY) && shape.attr('y', scaleY);
            break;
          case 'circle':
            scaleY = ((item.geo.h + item.geo.y) - (ny));
            d3Valid.num(scaleY) && shape.attr('cy', scaleY);
            break;
          case 'ellipse':
            scaleY = ((item.geo.h + item.geo.y) - (ny));
            d3Valid.num(scaleY) && shape.attr('cy', scaleY);
            break;
          case 'image':
            scaleY = ((item.geo.h + item.geo.y) - (ny + geometry.h));
            d3Valid.num(scaleY) && shape.attr('y', scaleY);
            break;
          case 'point':
            scaleY = ((item.geo.h + item.geo.y) - (ny));
            d3Valid.num(scaleY) && shape.attr('cy', scaleY);
            break;
          case 'line':
            var lx1 = geometry.p[0] + item.geo.x, ly1 = geometry.p[1], lx2 = geometry.p[2] + item.geo.x, ly2 = geometry.p[3];
            var scaleY1 = ((item.geo.h + item.geo.y) - (ly1)), scaleY2 = ((item.geo.h + item.geo.y) - (ly2));
            d3Valid.num(lx1) && shape.attr('x1', lx1);
            d3Valid.num(scaleY1) && shape.attr('y1', scaleY1);
            d3Valid.num(lx2) && shape.attr('x2', lx2);
            d3Valid.num(scaleY2) && shape.attr('y2', scaleY2);
            break;
          case 'arc':
            var ax1 = geometry.p[0][0] + item.geo.x, ay1 = geometry.p[0][1], ax2 = geometry.p[1][0] + item.geo.x, ay2 = geometry.p[1][1], ax3 = geometry.p[2][0] + item.geo.x, ay3 = geometry.p[2][1];
  
            var scaleAY1 = ((item.geo.h + item.geo.y) - (ay1)), scaleAY2 = ((item.geo.h + item.geo.y) - (ay2)), scaleAY3 = ((item.geo.h + item.geo.y) - (ay3));
  
            var point = [
              [ax1, scaleAY1],
              [ax2, scaleAY2],
              [ax3, scaleAY3]
            ];
  
            var arcPath = d3.line()
              .x(function (d) {
                return d[0];
              })
              .y(function (d) {
                return d[1];
              })
              .curve(d3.curveBasis);
            shape.attr('d', arcPath(point));
            shape.attr('point', point);
            break;
          case 'text':
            scaleY = ((item.geo.h + item.geo.y) - (ny));
            shape.attr('transform', 'rotate(-' + parseInt(geometry.a) + ' ' + nx + ' ' + scaleY + ')');
            d3Valid.num(scaleY) && shape.attr('y', scaleY);
            break;
        }
  
      } else {
        switch (type) {
          case 'rectangle':
            scaleY = item.geo.y;
            d3Valid.num(scaleY) && shape.attr('y', scaleY);
            break;
          case 'circle':
            scaleY = ny + item.geo.y;
            d3Valid.num(scaleY) && shape.attr('cy', scaleY);
            break;
          case 'ellipse':
            scaleY = ny + item.geo.y;
            d3Valid.num(scaleY) && shape.attr('cy', scaleY);
            break;
          case 'image':
            scaleY = ny + item.geo.y;
            d3Valid.num(scaleY) && shape.attr('y', scaleY);
            break;
          case 'point':
            scaleY = ny;
            d3Valid.num(scaleY) && shape.attr('cy', scaleY);
            break;
          case 'line':
            var lx1 = geometry.p[0] + item.geo.x, ly1 = geometry.p[1], lx2 = geometry.p[2] + item.geo.x, ly2 = geometry.p[3];
            var scaleY1 = ly1 + item.geo.y, scaleY2 = ly2 + item.geo.y;
            d3Valid.num(lx1) && shape.attr('x1', lx1);
            d3Valid.num(scaleY1) && shape.attr('y1', scaleY1);
            d3Valid.num(lx2) && shape.attr('x2', lx2);
            d3Valid.num(scaleY2) && shape.attr('y2', scaleY2);
            break;
          case 'arc':
            var ax1 = geometry.p[0][0] + item.geo.x, ay1 = geometry.p[0][1], ax2 = geometry.p[1][0] + item.geo.x, ay2 = geometry.p[1][1], ax3 = geometry.p[2][0] + item.geo.x, ay3 = geometry.p[2][1];
  
            var scaleAY1 = ay1 + item.geo.y, scaleAY2 = ay2 + item.geo.y, scaleAY3 = ay3 + item.geo.y;
  
            var point = [
              [ax1, scaleAY1],
              [ax2, scaleAY2],
              [ax3, scaleAY3]
            ];
  
            var arcPath = d3.line()
              .x(function (d) {
                return d[0];
              })
              .y(function (d) {
                return d[1];
              })
              .curve(d3.curveBasis);
            shape.attr('d', arcPath(point));
            shape.attr('point', point);
            break;
          case 'text':
            scaleY = ny + item.geo.y;
            shape.attr('transform', 'rotate(-' + parseInt(geometry.a) + ' ' + nx + ' ' + scaleY + ')');
            d3Valid.num(scaleY) && shape.attr('y', scaleY);
            break;
        }
      }
  
      //console.log(scaleY)
      if (style) {
        for (var index in style) {
          var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          shape.attr(styleName, style[index]);
        }
      }
  
      item.nodeElements.push(shape);
  
      return this;
    }
    addLabel(value, style) {
      var parent = this, geo = parent.geo, label, model = parent.model, scale = model.zoomable.scale;
      if (value) {
  
        if (parent.type === 'tree') {
          var d3label = new d3Label(), model = this.model, trimmedValue, tx = geo.x + parent.style.spaceAfterPoint;
  
          if (style.maxLength && value.length > style.maxLength) {
            trimmedValue = value.substring(0, style.maxLength);
            if (style.ellipsis) {
              trimmedValue += '...';
            }
          } else {
            trimmedValue = value;
          }
  
  
          var text = parent.node.append('text');
          d3Valid.num(tx) && text.attr("x", tx);
          d3Valid.num(geo.y) && text.attr("y", geo.y + 2.5);
          d3Valid.num(trimmedValue) && text.text(trimmedValue);
          text.attr('alignment-baseline', 'hanging').attr('fill', style.textColor);
  
          parent.nodeElements.push(text);
          model.svgElements.push(text);
          d3label.shapes.push(text);
          label = d3label;
  
        } else if (parent.type === 'div') {
          var trimmedValue;
          if (style.maxLength && value.length > style.maxLength) {
            trimmedValue = value.substring(0, style.maxLength);
            if (style.ellipsis) {
              trimmedValue += '...';
            }
          } else {
            trimmedValue = value;
          }
          var text = parent.node.append('p').attr('class', 'paragraph').html(trimmedValue);
  
        } else {
          var text = this.drawValue(value, geo, style);
  
          if (style.horizontalAlign) {
            if (style.horizontalAlign === 'right') {
              var rx = (geo.x + geo.w);
              text.node.attr('text-anchor', 'end');
              d3Valid.num(rx) && text.node.selectAll('text').attr("x", rx);
            } else if (style.horizontalAlign === 'center') {
              var c = geo.w / 2, cx = geo.x + c;
              text.node.attr('text-anchor', 'middle');
              d3Valid.num(cx) && text.node.selectAll('text').attr("x", cx);
            } else {
              var lx = geo.x;
              text.node.attr('text-anchor', 'start');
              d3Valid.num(lx) && text.node.selectAll('text').attr("x", lx);
            }
          }
  
  
          var textBox = parent.getBBox(text.node), textBoxHeight = textBox.height / scale;
  
  
  
          if (style.verticalAlign) {
            if (style.verticalAlign === 'middle') {
  
              var h = (geo.h / 2), ht = (textBoxHeight / 2) - 2.5, mp = h - ht;
  
              for (var i in text.shapes) {
                var item = text.shapes[i];
                var y = parseFloat(item.attr('y'));
                d3Valid.num(y) && item.attr('y', (y + mp));
              }
  
            } else if (style.verticalAlign === 'bottom') {
              var vt = textBoxHeight - 2.5;
              var bp = (geo.h - vt);
              for (var j in text.shapes) {
                var item = text.shapes[j];
                var y = parseFloat(item.attr('y'));
                d3Valid.num(y) && item.attr('y', (y + bp));
              }
            }
          }
  
          if (style.position) {
            for (var j in text.shapes) {
              var item = text.shapes[j], x = parseFloat(item.attr('x')), y = parseFloat(item.attr('y'));
              d3Valid.num(x + style.position[0]) && item.attr('x', x + style.position[0]);
              d3Valid.num(y + style.position[1]) && item.attr('y', y + style.position[1]);
            }
          }
          //.attr('transform', 'rotate('+style.rotation+')').attr('transform-origin', 'center center')
          if (style.rotation) {
            for (var j in text.shapes) {
              var item = text.shapes[j], x = parseFloat(item.attr('x')), y = parseFloat(item.attr('y'));
              text.node.attr('transform', 'rotate(' + style.rotation + ')');
            }
          }
          label = text;
        }
  
  
      }
      this.label = label;
      return this;
    }
    //Text Value inside the shape. Only Applicable for Rectangle and Circle
    //It will text based on the instruction
    drawValue(value, geo, style) {
      var parent = this;
      var group = parent.node;
      var text = group.append("g");
      var model = this.model;
      var scale = model.zoomable.scale;
      var d3label = new d3Label(), trimedValue;
  
      if (style.maxLength) {
        trimedValue = value.substring(0, style.maxLength);
        if (style.ellipsis) {
          trimedValue += '...';
        }
      } else {
        trimedValue = value;
      }
  
      var newText = text.append('text');
      d3Valid.num(geo.x) && newText.attr("x", geo.x);
      d3Valid.num(geo.y) && newText.attr("y", geo.y);
      newText.attr('alignment-baseline', 'hanging');
  
      if (style.wordWrap) {
        var words = trimedValue.split(/\s+/).reverse(), word, line = [], lineNumber = 0, lineHeight = style.lineHeight, wrapWidth = style.wrapWidth ? style.wrapWidth : geo.w;
  
        parent.nodeElements.push(newText);
        model.svgElements.push(newText);
        d3label.shapes.push(newText);
        while (word = words.pop()) {
          line.push(word);
          newText.text(line.join(" "));
          if (newText.node().getComputedTextLength() > wrapWidth) {
            line.pop();
            newText.text(line.join(" "));
            line = [word];
            newText = text.append("text").attr("x", geo.x).attr("y", (++lineNumber * lineHeight + geo.y)).text(word).attr('alignment-baseline', 'hanging');
            parent.nodeElements.push(newText);
            model.svgElements.push(newText);
            d3label.shapes.push(newText);
          }
        }
  
      } else {
        newText.text(trimedValue);
      }
  
  
      text.attr("fill", "#000000").attr("font-size", "12px").attr("font-family", "arial");
  
      if (style) {
        for (var index in style) {
          var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          text.attr(styleName, style[index]);
        }
      }
  
      text.attr("stroke-width", 0);
      d3label.node = text;
      d3label.parent = parent;
      d3label.style = style;
  
      return d3label;
    }
    use(id, x, y, w, h, href, style, relative) {
      var item = this, model = item.model, node;
      if (relative) {
        node = d3Shape.use(this.node, item.geo.x, item.geo.y, item.geo.w, item.geo.h);
      } else {
        node = d3Shape.use(this.node, x, y, w, h);
      }
      d3Valid.str(id) && node.attr('id', id);
      d3Valid.str(href) && node.attr("xlink:href", '#' + href);
  
      if (style) {
        for (var i in style) {
          node.attr(i, style[i]);
        }
      }
  
      return node;
    }
    setData(data) {
      this.data = data;
      return this;
    }
    updateData(data) {
      var existData = this.data;
      Object.assign(data, existData);
      return this;
    }
    setAttribute(data) {
      for (var i in data) {
        this.node.attr(i, data[i]);
      }
      return this;
    }
    setShapeAttr(data, shape) {
      if (shape) {
        for (var i in data) {
          shape.attr(i, data[i]);
        }
      }
      return this;
    }
    setTitle(title) {
      if (title) {
        this.node.append('title').text(title);
        this.node.attr('title', title);
      }
      return this;
    }
    setStyle(style) {
      var item = this;
      if (style) {
        for (var i in style) {
          this.node.style(i, style[i]);
        }
      }
      return this;
    }
    setCss(style, value) {
      var item = this, target = value ? this.node : this.shape;
      if (style) {
        for (var i in style) {
          target.style(i, style[i]);
        }
      }
      return this;
    }
    clearCss(style, value) {
      var item = this, target = value ? this.node : this.shape;
      target.attr('style', null);
      return this;
    }
    setClass(newClass) {
      var item = this;
      item.node.classed(newClass, true);
      return this;
    }
    removeClass(newClass) {
      var item = this;
      item.node.classed(newClass, false);
      return this;
    }
    selectable(value, type, action, style) {
      var item = this, model = this.model;
      if (value) {
        model.selectable[this.id] = item;
        item.selectionEnabled = true;
        item.selectionAction = action;
        item.selectionStyle = style;
  
  
        item.node.on('mouseover', function () {
          if (action.onFocus)
            action.onFocus(item);
        });
        item.node.on('mouseout', function () {
          if (action.onBlur)
            action.onBlur(item);
        });
        item.node.on('click', function (event) {
          if (type === 'multiple') {
            if (item.selected) {
              item.selected = false;
              item.highlight(false);
              if (action.onUnselect)
                action.onUnselect(item, event);
            } else {
              item.type !== 'div' && item.moveToFront();
              item.highlight(true, style);
              item.selected = true;
              if (action.onSelect)
                action.onSelect(item, event);
            }
          } else {
            if (item.selected) {
              item.selected = false;
              item.highlight(false);
              if (action.onUnselect)
                action.onUnselect(item, event);
            } else {
              for (var m in model.selectable) {
                var selectableItem = model.selectable[m];
                if (selectableItem.selected) {
                  selectableItem.selected = false;
                  selectableItem.highlight(false);
                }
              }
  
              item.type !== 'div' && item.moveToFront();
              item.highlight(true, style);
              item.selected = true;
              if (action.onSelect)
                action.onSelect(item, event);
            }
          }
  
          d3.event.stopPropagation();
  
        });
        d3.select(document).on('click', function (event) {
          d3.select('.d3ContextMenu').remove();
          if (action.onBubble)
            action.onBubble(event);
        });
      } else {
        delete model.selectable[this.id];
        item.selectionEnabled = false;
        item.selectionAction = action;
        item.selectionStyle = '';
      }
  
      return this;
    }
    select(value, style) {
      var item = this, model = this.model;
      if (value) {
        model.selectable[this.id] = item;
        item.selectionStyle = style;
        style.moveToFront && item.moveToFront();
        item.highlight(true, style);
        item.selected = true;
      } else {
        item.selected = false;
        item.highlight(false, style);
      }
    }
    onSelect(action) {
      this.model.selectable[this.id] = this;
      var item = this, model = this.model;
      model.selectable[this.id] = item;
      item.node.attr('cursor', 'pointer');
      item.selection = action;
      item.node.on('click', function () {
        if (d3.event.defaultPrevented)
          return; // dragged
        for (var i in model.selectable) {
          var selItem = model.selectable[i];
          if (selItem !== item) {
            selItem.selection.blur(selItem);
            selItem.selected = false;
          }
        }
        item.selected = true;
        action.focus(item);
        d3.event.stopPropagation();
      });
  
      d3.select('svg').on('click', function () {
        item.selected = false;
        for (var i in model.selectable) {
          var selItem = model.selectable[i];
          selItem.selection.blur(selItem);
          selItem.selected = false;
        }
      });
  
      return this;
    }
    addAssist(style) {
      var item = this, model = item.model;
      var assist = model.d3Graph.dragAssist.append('rect');
      d3Valid.num(item.geo.x) && assist.attr("x", item.geo.x);
      d3Valid.num(item.geo.y) && assist.attr("y", item.geo.y);
      d3Valid.num(item.geo.w) && assist.attr("width", item.geo.w);
      d3Valid.num(item.geo.h) && assist.attr("height", item.geo.h);
      assist.attr('stroke', '#01bbd4')
        .attr('stroke-width', 1)
        .attr('fill', '#ffffff')
        .attr("transform", "translate(" + 0 + "," + 0 + ")");
  
      model.d3Graph.dragAssist.each(function () {
        this.parentNode.appendChild(this);
      });
  
      if (style) {
        for (var i in style) {
          assist.attr(i, style[i]);
        }
      }
  
      item.customAssist = assist;
  
      return this;
    }
    removeAssist(style) {
      var item = this, model = item.model;
      item.customAssist.remove();
      item.customAssist = '';
    }
    showAssist() {
      var item = this, model = item.model;
      item.customAssist.style('display', 'block');
    }
    hideAssist() {
      var item = this, model = item.model;
      item.customAssist.style('display', 'none');
    }
    visibleAssist(value) {
      var item = this, model = item.model;
      value ? item.customAssist.style('opacity', 1) : item.customAssist.style('opacity', 0);
    }
    onClick(action) {
      var item = this;
      item.node.on('click', function (event) {
        action(item, event);
        d3.event.stopPropagation();
      });
      return this;
    }
    onHover(mouseover, mouseout) {
      var item = this;
      if (mouseover) {
        item.node.on('mouseover', function () {
          mouseover(item);
          d3.event.stopPropagation();
        });
      }
  
      if (mouseout) {
        item.node.on('mouseout', function () {
          mouseout(item);
          d3.event.stopPropagation();
        });
      }
  
      return this;
    }
    onMouseover(action) {
      var item = this;
      item.node.on('mouseover', function () {
        action(item);
        d3.event.stopPropagation();
      });
      return this;
    }
    onMouseout(action) {
      var item = this;
      tem.node.on('mouseout', function () {
        mouseout(item);
        d3.event.stopPropagation();
      });
      return this;
    }
    draggable(value, action, custom, handle) {
      var item = this, assisted = false, model = this.model;
      item.action = action;
  
      if (value === undefined || value) {
  
        item.dragEnabled = true;
  
        if (handle) {
          if (model.d3Graph.touchDevice) {
            handle.node.on('touchstart', function () {
              item.node.datum({
                x: item.geo.x,
                y: item.geo.y
              }).call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragend));
            });
            handle.node.on('touchend', function () {
              item.node.on('mousedown.drag', null);
            });
          } else {
            handle.node.on('mousedown', function () {
              item.node.datum({
                x: item.geo.x,
                y: item.geo.y
              }).call(d3.drag()
                .on("start", dragstarted)
                .on("drag", dragged)
                .on("end", dragend));
            });
            handle.node.on('mouseleave', function () {
              item.node.on('mousedown.drag', null);
            });
          }
        } else {
          item.node.datum({
            x: item.geo.x,
            y: item.geo.y
          }).call(d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragend));
        }
  
  
  
        function dragstarted(d) {
          d3.select(this).attr('cursor', 'default');
          d3.select('.d3ContextMenu').remove();
  
          if (item.style.dragAssist && item.style.dragAssist !== 'origin') {
            var zoomScale = model.zoomable.scale, zoomTranslate = model.zoomable.translate;
  
            var d3Mouse = d3.mouse(model.d3Graph.svg.node());
  
  
            var mouseX = d3Mouse[0], mouseY = d3Mouse[1], assistX, assistY;
  
            if (item.type === 'div') {
              assistX = mouseX - ((item.geo.w / 2) * zoomScale);
              assistY = mouseY - ((item.geo.h / 2) * zoomScale);
            } else {
              assistX = item.getBBox().x;
              assistY = item.getBBox().y;
            }
  
  
            var aStyle;
            model.d3Graph.dragAssist.each(function () {
              this.parentNode.appendChild(this);
            });
  
            assisted = true;
  
            var adx, ady;
  
            if (item.type === 'div') {
              d.x = assistX;
              d.y = assistY;
              adx = d.x / zoomScale;
              ady = d.y / zoomScale;
            } else {
              d.x = assistX / zoomScale;
              d.y = assistY / zoomScale;
              adx = d.x;
              ady = d.y;
            }
            var assistant;
  
            if (item.style.dragAssist === 'box') {
              assistant = model.d3Graph.dragAssist.append('rect');
              d3Valid.num(adx) && assistant.attr("x", adx);
              d3Valid.num(ady) && assistant.attr("y", ady);
              d3Valid.num(item.geo.w) && assistant.attr("width", item.geo.w);
              d3Valid.num(item.geo.h) && assistant.attr("height", item.geo.h);
              assistant.attr("transform", "translate(" + 0 + "," + 0 + ")");
            } else {
              var clone, itemBBox;
              if (item.style.dragAssist === 'clone') {
                clone = item.node.html();
                itemBBox = item.getBBox();
              } else if (item.style.dragAssist === 'custom') {
                clone = custom.node.html();
                itemBBox = custom.getBBox();
              }
              // d.x = assistX;
              // d.y = assistY;
              d.x = d3Mouse[0];
              d.y = d3Mouse[1];
  
              var itx = itemBBox.x + (itemBBox.width / 2);
              var ity = itemBBox.y + (itemBBox.height / 2);
  
              //model.d3Graph.dragAssist.attr("transform", "translate(" + itx + "," + ity + ") scale(" + zoomScale + ") translate(-" + itx + ",-" + ity + ")");
              assistant = model.d3Graph.dragAssist.append('g')
                .attr("transform", "translate(" + 0 + "," + 0 + ")")
                .html(String(clone));
            }
  
  
            assistant.attr('cursor', 'default');
            assistant.style('display', 'none');
  
            item.style && item.style.assistFill ? assistant.attr("fill", item.style.assistFill) : assistant.attr("fill", "#FFFFFF");
            item.style && item.style.assistStroke ? assistant.attr("stroke", item.style.assistStroke) : assistant.attr("stroke", "#000000");
            item.style && item.style.assistStrokeWidth ? assistant.attr("stroke-width", item.style.assistStrokeWidth) : assistant.attr("stroke-width", 1);
  
  
            item.dragAssist = assistant;
          } else {
            d.x = 0;
            d.y = 0;
          }
  
  
  
          if (action)
            action.start(item, d);
          d3.event.sourceEvent.stopPropagation();
  
  
        }
  
        function dragged(d) {
          var node = item.node, model = item.model, parent = item.parent, parentBBox = parent.getBBox(), assistBBox = item.getBBox(item.dragAssist);
          //console.log(parentBBox)
          if (d3.event.dx && d3.event.dy) {
            item.dragging = true;
            item.dragAssist && item.dragAssist.style('display', 'block');
          }
  
          var zoomScale = model.zoomable.scale, zoomTranslate = model.zoomable.translate;
  
          d.x += d3.event.dx;
          d.y += d3.event.dy;
  
  
          if (assisted) {
            if (item.style.dragAssist === 'box') {
              if (item.type === 'div') {
                item.dragAssist.attr("x", d.x / zoomScale).attr("y", d.y / zoomScale);
              } else {
                item.dragAssist.attr("x", d.x).attr("y", d.y);
              }
            } else if (item.style.dragAssist === 'clone' || item.style.dragAssist === 'custom') {
              item.dragAssist.attr("transform", "scale(1) translate(" + d.x / zoomScale + "," + d.y / zoomScale + ")");
            }
  
  
  
            if (item.style.moveOriginal) {
              delete parent.children[item.id];
              item.parent = model.d3Graph;
              model.d3Graph.children[item.id] = item;
              item.node.attr("transform", "translate(" + d.x + "," + d.y + ")");
            }
  
  
          } else {
            item.node.attr("transform", "translate(" + d.x + "," + d.y + ")");
          }
          if (action)
            action.drag(item, d);
        }
  
        function dragend(d) {
          d3.event.sourceEvent.preventDefault();
          item.dragging = false;
  
          if (item.type === 'window') {
            item.setPosition(d);
          }
  
          item.node.attr('transform', 'translate(' + 0 + ', ' + 0 + ')');
          d3.select(this).attr('cursor', 'default');
  
  
          if (action)
            action.end(item, d);
  
          if (assisted) {
            item.node.style('opacity', 1);
            item.dragAssist.remove();
            item.dragAssist = '';
          }
  
          d3.event.sourceEvent.stopPropagation();
        }
  
        item.model.draggable[item.id] = item;
  
      } else {
        item.node.on('mousedown.drag', null);
        item.dragEnabled = false;
      }
      return this;
    }
    //d3Vertex Update Functions
    updateVertex(id, x, y, w, h) {
  
  
      var item = this, model = item.model, ux, uy, oid = item.id;
  
      if (item.relative) {
        ux = x + item.parent.geo.x;
        uy = y + item.parent.geo.y;
      } else {
        ux = x;
        uy = y;
      }
  
  
      var d = {};
      d.x = ux - item.geo.x;
      d.y = uy - item.geo.y;
  
      var uw = w, uh = h, uid = id;
  
      if (uid !== oid) {
        model.verticesArray.push(item);
        var index = model.verticesArray.map(function (v) {
          return v.id;
        }).indexOf(oid);
        if (index)
          model.verticesArray.splice(index, 1);
        item.parent.children[uid] = item;
        delete item.parent.children[oid];
        model.vertices[uid] = item;
        delete model.vertices[oid];
  
        if (item.dragEnabled) {
          model.draggable[uid] = item;
          delete model.draggable[oid];
        }
        if (item.dropEnabled) {
          model.droppable[uid] = item;
          delete model.droppable[oid];
        }
        if (item.selectionEnabled) {
          model.selectable[uid] = item;
          delete model.selectable[oid];
        }
  
        item.node.attr('id', uid);
        item.id = uid;
      }
  
      this.updateSize(uw, uh);
  
      this.setPosition(d);
  
      return this;
    }
    updateLabel(value, style) {
  
      var item = this, model = item.model;
  
      if (item.label) {
        item.label.node.remove();
        item.label = {};
      }
  
      item.addLabel(value, style);
  
      return this;
    }
    setPosition(d) {
      var item = this;
  
      item.geo.x = item.geo.x + d.x;
      item.geo.y = item.geo.y + d.y;
  
      for (var i in item.nodeElements) {
        var shape = item.nodeElements[i];
        _updateAttr(shape, d);
        _updatePosition(item);
      }
  
      if (item.grid) {
        for (var i in item.grid.gridBoxes) {
          var gridBox = item.grid.gridBoxes[i];
          _updateAttr(gridBox, d);
        }
        item.grid.geo.x = item.grid.parent.geo.x;
        item.grid.geo.y = item.grid.parent.geo.y;
      }
  
      _moveAllChildren(item, d);
  
      return this;
    }
    //d3Vertex updateSize Functions
    updateSize(w, h) {
      var item = this;
      if (!isNaN(w)) {
        item.shape.attr('width', w);
        item.geo.w = w;
      }
      if (!isNaN(h)) {
        item.shape.attr('height', h);
        item.geo.h = h;
      }
      return this;
    }
    snapTo(cell) {
      var model = this.model, zoomScale = model.zoomable.scale, item = this;
  
      if (cell) {
        var cellBBox = cell.getBBox(), cx = cellBBox.left, cy = cellBBox.top;
  
        item.dragAssist.attr('x', cx / zoomScale).attr('y', cy / zoomScale);
        item.snappedTo = cell;
      } else {
        item.snappedTo = undefined;
      }
  
      return this;
    }
    // point should be minus or plus value
    // eg: -5, or +5
    // -5 will reduce 5 px to inside & +5 will increase 5 px to ouside
    getOverlap(point, custom) {
  
      var item = this, model = item.model, assist = custom ? item.customAssist : item.dragAssist, dragBBox = item.getBBox(assist), overlapped, scale = model.draggable.scale;
  
      for (var i in model.verticesArray) {
        var vertex = model.verticesArray[i];
  
        if (vertex) {
          var vertexBBox = vertex.getBBox();
          var VL = vertexBBox.left, VR = vertexBBox.right, VT = vertexBBox.top, VB = vertexBBox.bottom, DL = dragBBox.left, DR = dragBBox.right, DT = dragBBox.top, DB = dragBBox.bottom;
  
          var point = point ? point : 0;
  
          var dragInside = ((DL > (VL - point) && DL < (VR + point)) || (DR < (VR + point) && DR > (VL - point))) &&
            ((DT > (VT - point) && DT < (VB + point)) || (DB < (VB + point) && DB > (VT - point)));
  
          var vertexInside = ((VL > (DL - point) && VL < (DR + point)) || (VR < (DR + point) && VR > (DL - point))) &&
            ((VT > (DT - point) && VT < (DB + point)) || (VB < (DB + point) && VB > (DT - point)));
  
          var exact = (DL === (VL - point)) && (DR === (VR - point)) && (DT === (VT - point)) && (DB === (VB - point));
  
          if (dragInside || exact) {
            overlapped = vertex;
          }
        }
  
      }
  
  
      return overlapped;
    }
    getAllOverlap(point, custom) {
  
      var item = this, model = item.model, assist = custom ? item.customAssist : item.dragAssist, dragBBox = item.getBBox(assist), overlapped = [], scale = model.draggable.scale;
  
      for (var i in model.vertices) {
        var vertex = model.vertices[i];
  
        if (vertex) {
          var vertexBBox = vertex.getBBox();
          var VL = Math.round(vertexBBox.left), VR = Math.round(vertexBBox.right), VT = Math.round(vertexBBox.top), VB = Math.round(vertexBBox.bottom), DL = Math.round(dragBBox.left), DR = Math.round(dragBBox.right), DT = Math.round(dragBBox.top), DB = Math.round(dragBBox.bottom);
  
          var point = point ? point : 0;
  
          var dragInside = ((DL > (VL - point) && DL < (VR + point)) || (DR < (VR + point) && DR > (VL - point))) &&
            ((DT > (VT - point) && DT < (VB + point)) || (DB < (VB + point) && DB > (VT - point)));
  
          var vertexInside = ((VL > (DL - point) && VL < (DR + point)) || (VR < (DR + point) && VR > (DL - point))) &&
            ((VT > (DT - point) && VT < (DB + point)) || (VB < (DB + point) && VB > (DT - point)));
  
          var exact = (DL === (VL - point)) && (DR === (VR - point)) && (DT === (VT - point)) && (DB === (VB - point));
  
          if (dragInside || exact) {
            overlapped.push(vertex);
          }
        }
  
      }
  
  
  
      return overlapped;
    }
    getCollision(point, custom) {
  
      var item = this, model = item.model, assist = custom ? item.customAssist : item.dragAssist, dragBBox = item.getBBox(assist), overlapped = [], scale = model.draggable.scale, point = point || 0;
  
      for (var i in model.verticesArray) {
        var vertex = model.verticesArray[i];
  
        if (vertex) {
          var vertexBBox = vertex.getBBox();
          var VL = vertexBBox.left, VR = vertexBBox.right, VT = vertexBBox.top, VB = vertexBBox.bottom, DL = dragBBox.left - point, DR = dragBBox.right + point, DT = dragBBox.top - point, DB = dragBBox.bottom + point;
  
          var collideHoriz = DL < VR && DR > VL;
          var collideVert = DT < VB && DB > VT;
  
          if (collideHoriz && collideVert) {
            overlapped.push(vertex);
          }
        }
  
      }
  
  
      return overlapped;
    }
    getInside(custom) {
  
      var item = this, model = item.model, assist = custom ? item.customAssist : item.dragAssist, dragBBox = item.getBBox(assist), insided = [];
  
      for (var i in model.vertices) {
        var vertex = model.vertices[i];
  
        if (vertex) {
          var vertexBBox = vertex.getBBox();
          var inside = ((vertexBBox.top <= dragBBox.top) && (dragBBox.top <= vertexBBox.bottom)) &&
            ((vertexBBox.top <= dragBBox.bottom) && (dragBBox.bottom <= vertexBBox.bottom)) &&
            ((vertexBBox.left <= dragBBox.left) && (dragBBox.left <= vertexBBox.right)) &&
            ((vertexBBox.left <= dragBBox.right) && (dragBBox.right <= vertexBBox.right));
  
  
          if (inside) {
            insided.push(vertex);
          }
        }
  
      }
  
  
      return insided;
    }
    dropTo(cell) {
      var item = this, node = this.node, model = this.model, parent = this.parent;
      node.each(function () {
        cell.node.node().appendChild(this);
      });
  
      delete parent.children[item.id];
  
      item.parent = cell;
      cell.children[item.id] = item;
      return this;
    }
    droppable(value) {
      var item = this;
      if (value === undefined || value) {
        item.dropEnabled = true;
        item.model.droppable[item.id] = item;
      } else {
        item.dropEnabled = false;
      }
  
      return this;
    }
    updateLink(translate) {
      var item = this;
      for (var index in item.links) {
        if (item.links[index].source.node === item.node) {
          var geo = item.geo;
          var sx = geo.x + translate.x, sy = geo.y + translate.y, sw = geo.w, sh = geo.h;
  
          var target = item.links[index].target;
          var targetGeo = target.geo;
  
          var tx = targetGeo.x, ty = targetGeo.y, tw = targetGeo.w, th = targetGeo.h;
  
          var sGeo = [sx, sy, sw, sh], tGeo = [tx, ty, tw, th];
  
          var linePath = drawLinePath(sGeo, tGeo);
          item.links[index].line.attr('d', linePath);
        } else if (item.links[index].target.node === item.node) {
          var geo = item.geo;
          var tx = geo.x + translate.x, ty = geo.y + translate.y, tw = geo.w, th = geo.h;
  
          var source = item.links[index].source;
          var sourceGeo = source.geo;
  
          //var sourceTranslate = d3.transform(source.node.node().getAttribute("transform")).translate;
          //var sourceTranslate = source.node.node().transform.baseVal[0].matrix;
          var sx = sourceGeo.x, sy = sourceGeo.y, sw = sourceGeo.w, sh = sourceGeo.h;
  
          var sGeo = [sx, sy, sw, sh], tGeo = [tx, ty, tw, th];
  
          var linePath = drawLinePath(sGeo, tGeo);
          item.links[index].line.attr('d', linePath);
        }
      }
  
      return this;
    }
    insertLink(id, source, target, type, style) {
      return new d3Link(id, this, source, target, type, style);
    }
    addContextMenu(menu, id) {
      var parent = this;
      var vertex = _updatePosition(parent);
  
      var cursor = menu.cursor ? menu.cursor : 'pointer';
      var node = vertex.node.append('g').attr('cursor', 'pointer');
      var pX = vertex.geo.x, pY = vertex.geo.y, pW = vertex.geo.w, pH = vertex.geo.h, x, y;
  
      if (menu.align[1] === 'left') {
        x = pX + menu.position[0];
      } else if (menu.align[1] === 'center') {
        x = ((pX + (pW / 2)) - (menu.size[0] / 2)) + menu.position[0];
      } else if (menu.align[1] === 'right') {
        x = ((pX + (pW)) - menu.size[0]) + menu.position[0];
      }
      if (menu.align[0] === 'top') {
        y = pY + menu.position[1];
      } else if (menu.align[0] === 'middle') {
        y = ((pY + (pH / 2)) - (menu.size[1] / 2)) + menu.position[1];
      } else if (menu.align[0] === 'bottom') {
        y = ((pY + (pH)) - menu.size[1]) + menu.position[1];
      }
  
      var image = node.append('svg:image');
      d3Valid.str(menu.image) && image.attr('xlink:href', menu.image);
      d3Valid.num(x) && image.attr("x", x);
      d3Valid.num(y) && image.attr("y", y);
      d3Valid.num(menu.size[0]) && image.attr("width", menu.size[0]);
      d3Valid.num(menu.size[1]) && image.attr("height", menu.size[1]);
      d3Valid.str(menu.title) && image.attr('title', menu.title);
  
      if (d3Valid.str(menu.title)) {
        var titleObj = {};
        titleObj.text = menu.title;
        var titleNode = node.append('title').text(menu.title);
        titleObj.node = titleNode;
  
        menu.title = titleObj;
      }
  
      node.on('mousedown', function () {
        d3.event.stopPropagation();
      });
  
      d3.select(document).on('click', function (event) {
        d3.select('.d3ContextMenu').remove();
      });
  
      if (menu.itemCount) {
        this.createMenu(node, menu, vertex);
      }
  
      menu.node = node;
      menu.icon = image;
      menu.geo = {
        x: x,
        y: y,
        w: menu.size[0],
        h: menu.size[1]
      };
  
      vertex.nodeElements.push(image);
      parent.contextMenus[id] = menu;
  
      return this;
  
    }
    createMenu(trigger, menu, vertex) {
      var d3Drawing = this;
      trigger.on('click', function (event) {
        d3.select('.d3ContextMenu').remove();
        var coordinates = [window.event.clientX, window.event.clientY];
        var d3ContextMenu = d3.select('body').append('div')
          .attr('class', 'd3ContextMenu')
          .attr('style', 'top: ' + coordinates[1] + 'px; left: ' + coordinates[0] + 'px;')
          .append('ul');
  
        for (var index in menu.items) {
          var item = menu.items[index], list;
          if (!item.parent) {
            list = d3ContextMenu.append('li').attr('index', index);
            var menuIcon = list.append('div').attr('class', 'd3ContextMenuIcon'), menuItem = list.append('div').attr('class', 'd3ContextMenuItem');
            if (item.icon) {
              menuIcon.append('img').attr('src', item.icon).attr('alt', 'icon');
            }
  
            if (item.name) {
              menuItem.text(item.name);
            } else {
              menuItem.text('Menu Item');
            }
  
            item.node = list;
  
          } else {
            var arrow = menu.submenuIcon ? menu.submenuIcon : 'images/submenu.gif';
            if (list.select('.d3ContextMenuArrow').node() === null) {
              var menuArrow = list.append('div').attr('class', 'd3ContextMenuArrow')
                .append('img').attr('src', arrow).attr('alt', 'arrow');
            }
  
            if (list.select('ul').node() === null) {
              var subMenu = item.parent.node.append('ul');
            }
  
  
  
            var subList = subMenu.append('li').attr('index', index), menuIcon = subList.append('div').attr('class', 'd3ContextMenuIcon'), menuItem = subList.append('div').attr('class', 'd3ContextMenuItem');
  
            if (item.icon) {
              menuIcon.append('img').attr('src', item.icon).attr('alt', 'icon');
            }
  
            if (item.name) {
              menuItem.text(item.name);
            } else {
              menuItem.text('Menu Item');
            }
            var arrow = menu.submenuIcon ? menu.submenuIcon : 'images/submenu.gif';
            item.node = subList;
            item.parent.hasSubmenu = true;
  
          }
  
          if (!item.hasSubmenu && item.action) {
            item.node.on('click', function (event) {
  
              var i = d3.select(this).attr('index'), listItem = menu.items[i];
              listItem.action(listItem, vertex, event);
            });
          }
  
        }
  
  
        var windowBottom = window.innerHeight - 80;
        var menuBoxGeo = d3.select('.d3ContextMenu').node().getBoundingClientRect();
        var menuBoxBottom = menuBoxGeo.top + menuBoxGeo.height;
        if (menuBoxBottom > windowBottom) {
          var top = (menuBoxBottom - windowBottom) + 20;
          var menuX = coordinates[1] - top;
          console.log(menuX);
          d3.select('.d3ContextMenu').attr('style', 'top: ' + menuX + 'px; left: ' + coordinates[0] + 'px;');
        }
  
        d3.event.stopPropagation();
      });
    }
    removeContextMenu(id) {
      var parent = this;
      if (parent.contextMenus[id])
        parent.contextMenus[id].node.remove();
      delete parent.contextMenus[id];
    }
    addBadge(type, id, title, x, y, w, h, style, styleText, action, utility) {
      var parent = this;
      var vertex = _updatePosition(parent);
      var model = this.model;
      var pX = vertex.geo.x, pY = vertex.geo.y, pW = vertex.geo.w, pH = vertex.geo.h, nx, ny, fx, fy;
  
      var align = style && style.align ? style.align : ['left', 'top'];
  
      if (align[0] === 'top') {
        ny = pY + y;
      } else if (align[0] === 'middle') {
        ny = ((pY + (pH / 2)) - (h / 2)) + y;
      } else if (align[0] === 'bottom') {
        ny = ((pY + (pH)) - h) + y;
      } else {
        ny = pY + y;
      }
  
      if (align[1] === 'left') {
        nx = pX + x;
      } else if (align[1] === 'center') {
        nx = ((pX + (pW / 2)) - (w / 2)) + x;
      } else if (align[1] === 'right') {
        nx = ((pX + (pW)) - w) + x;
      } else {
        nx = pX + x;
      }
  
      var badge = new d3Badge();
  
  
      var geo = {
        x: nx,
        y: ny,
        w: w,
        h: h
      };
      var badgeShape, badgeObj = {};
  
      if (parent.type == 'div') {
        if (type === 'text') {
          badgeObj.node = this.node.append('p').text(utility).attr('class', 'd3-badge');
        } else {
          badgeObj.node = this.node.append('span').attr('class', 'd3-badge').append('img').attr('src', utility);
        }
        badgeShape = badgeObj;
      } else {
        if (type === 'text') {
          badgeShape = this.insertVertex('rectangle', id, nx, ny, w, h, style);
          var badgeText = badgeShape.addLabel(utility, styleText);
        } else {
          badgeShape = this.insertVertex('image', id, nx, ny, w, h, style, false, utility);
        }
      }
  
      if (title) {
        var titleObj = {};
        titleObj.text = title;
        if (parent.type == 'div') {
          badgeShape.node.attr('title', title);
        } else {
          var titleNode = badgeShape.node.append('title').text(title);
          titleObj.node = titleNode;
        }
  
        badge.title = titleObj;
      }
  
      if (model.d3Graph.touchDevice) {
        badgeShape.node.on('touchstart', function () {
          d3.event.stopPropagation();
        });
        badgeShape.node.on('touchend', function (event) {
          action(parent, event);
          d3.event.stopPropagation();
        });
      } else {
        badgeShape.node.on('mousedown', function () {
          d3.event.stopPropagation();
        });
        badgeShape.node.on('click', function (event) {
          action(parent, event);
          d3.event.stopPropagation();
        });
      }
  
  
  
  
      for (var i in badgeShape.nodeElements) {
        badge.nodeElements.push(badgeShape.nodeElements[i]);
      }
  
      badge.id = id;
      badge.type = 'badge';
      badge.geo = geo;
      badge.shape = badgeShape;
      badge.text = badgeText;
      badge.callBack = action;
      badge.parent = parent;
  
      this.badges[id] = badge;
  
      return this;
    }
    getBadge(id) {
      var item = this;
      var badge = item.badges[id];
      return badge;
    }
    showBadge(id) {
      var item = this;
      var badge = item.badges[id];
      badge.shape.node.style('display', 'block');
      return this;
    }
    hideBadge(id) {
      var item = this;
      var badge = item.badges[id];
      badge.shape.node.style('display', 'none');
      return this;
    }
    removeBadge(id) {
      var parent = this;
      if (parent.badges[id])
        parent.badges[id].shape.node.remove();
      delete parent.badges[id];
      delete parent.children[id];
    }
    open() {
      var item = this, model = this.model;
  
      var d3Window = model.d3Window[item.id];
  
      if (d3Window && d3Window.style.slideOverlay) {
        d3Window.window.node.transition().attr("transform", "translate(0, 0)");
      } else {
        d3Window.window.show();
      }
      d3Window.open = true;
  
      return this;
    }
    close() {
      var item = this, model = this.model;
  
      var d3Window = model.d3Window[item.id];
  
      if (d3Window && d3Window.open) {
        if (d3Window.style.slideOverlay) {
          var hideX;
          if (d3Window.style.slideOverlay === 'left') {
            hideX = d3Window.geo.x - d3Window.geo.w;
          } else {
            hideX = d3Window.geo.x + d3Window.geo.w;
          }
          d3Window.window.node.transition().attr("transform", "translate(" + hideX + ", 0)");
        } else {
          d3Window.window.hide();
        }
  
        d3Window.open = false;
      }
  
      return this;
    }
    isOpen() {
      var item = this, model = this.model;
  
      var d3Window = model.d3Window[item.id];
  
      if (d3Window && d3Window.style.slideOverlay && d3Window.open) {
        return d3Window.open;
      }
  
      return this;
    }
    treeLayout(id, x, y, w, h, style) {
      return new d3TreeLayout(this, id, x, y, w, h, style);
    }
    /*-----------------------------*/
    remove() {
      var item = this, model = this.model;
  
      delete model.draggable[item.id];
      delete model.droppable[item.id];
      var index = model.verticesArray.map(function (v) {
        return v.id;
      }).indexOf(item.id);
      if (index)
        model.verticesArray.splice(index, 1);
      delete model.vertices[item.id];
      delete model.selectable[item.id];
      delete model.links[item.id];
  
      for (var i in model.svgElements) {
        if (model.svgElements[i] === item.shape)
          model.svgElements.splice(i, 1);
      };
  
      item.node.remove();
    }
    clear() {
      var item = this, model = this.model;
  
      item.children = {};
      item.badges = {};
      item.links = {};
  
      item.node.selectAll("*").remove();
  
      return this;
    }
    moveToFront(item) {
      var node;
  
      if (item) {
        node = item.node;
      } else {
        node = this.node;
      }
  
      node.each(function () {
        this.parentNode.appendChild(this);
      });
      return this;
    }
    moveToBack(item) {
      var node;
  
      if (item) {
        node = item.node;
      } else {
        node = this.node;
      }
  
      node.each(function () {
        var firstChild = this.parentNode.firstChild;
        if (firstChild) {
          this.parentNode.insertBefore(this, firstChild);
        }
      });
      return this;
    }
    highlight(value, style, custom) {
      var item = this, assist = custom ? item.customAssist : item.dragAssist;
      if (value) {
        for (var i in style) {
          if (assist) {
            assist.attr(i, style[i]);
          } else {
            if (item.type === 'div') {
              item.setCss(style, true);
            } else {
              item.shape.attr(i, style[i]);
            }
          }
          item.highlighted = true;
        }
      } else {
        item.highlighted = false;
        var style = style ? style : item.style;
        if (assist) {
          assist.attr('stroke', style.assistStroke);
          assist.attr('fill', style.assistFill);
          assist.attr('stroke-width', style.assistStrokeWidth);
        } else {
          if (item.type === 'div') {
            item.clearCss(style, false);
          } else {
            item.shape.attr('stroke', style.stroke);
            item.shape.attr('fill', style.fill);
            item.shape.attr('stroke-width', style.strokeWidth);
          }
  
        }
      }
  
      return this;
    }
    childAs(cell) {
      var item = this;
  
      if (cell) {
        cell.children[item.id] = item;
      }
  
      return this;
    }
    //Parent.grid(id, x, y, width, height, Rows, Columns, style);
    setGrid(id, x, y, width, height, Rows, Columns, style) {
  
      var item = this;
  
      var node = item.node.append('g').attr('id', id);
  
      var w = width / Columns, h = height / Rows, gridBoxes = [];
  
      //console.log(Rows)
      for (var row = 0; row < Rows; row++) {
  
        for (var column = 0; column < Columns; column++) {
          var grid = node.append('rect'), nx = Array.isArray(x) ? x[column] ? x[column] : x[0] ? x[0] : 0 : x + (w * column), ny = Array.isArray(y) ? y[row] ? y[row] : y[0] ? y[0] : 0 : y + (h * row), nw = Array.isArray(width) ? width[0] ? width[0] : 0 : width / Columns, nh = Array.isArray(height) ? height[0] ? height[0] : 0 : height / Rows;
          d3Valid.num(nx) && grid.attr('x', nx);
          d3Valid.num(ny) && grid.attr('y', ny);
          d3Valid.num(w) && grid.attr('width', nw);
          d3Valid.num(h) && grid.attr('height', nh);
          grid.attr('stroke', style.stroke)
            .attr('stroke-width', style.strokeWidth)
            .attr("stroke-dasharray", style.strokeDashed)
            .attr('class', 'gridBox')
            .attr('hIndex', column)
            .attr('vIndex', row);
  
          gridBoxes.push(grid);
        }
      }
  
  
      if (style) {
        for (var index in style) {
          var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          node.attr(styleName, style[index]);
        }
      }
  
      var geo = {
        x: x,
        y: y,
        w: width,
        h: height
      };
  
      var grid = {};
      grid.node = node;
      grid.id = id;
      grid.geo = geo;
      grid.rows = Rows;
      grid.columns = Columns;
      grid.gridBoxes = gridBoxes;
      grid.parent = this;
      grid.active = false;
      grid.boxWidth = w;
      grid.boxHeight = h;
      grid.params = {
        id: id, x: x, y: y, width: width, height: height, Rows: Rows, Columns: Columns, style: style
      };
  
      var model = item.model;
      model.grid[id] = grid;
      item.grid = grid;
  
      return this;
    }
    //Parent.grid(id, x, y, width, height, Rows, Columns, style);
    reSetGrid(id, x, y, width, height, Rows, Columns, style) {
  
      var item = this;
      var model = this.model;
  
      delete model.grid[item.grid.id];
      item.grid.node.remove();
      delete item.grid;
  
  
      var node = item.node.append('g').attr('id', id);
  
      var w = width / Columns, h = height / Rows, gridBoxes = [];
  
      //console.log(Rows)
      for (var row = 0; row < Rows; row++) {
  
        for (var column = 0; column < Columns; column++) {
          var grid = node.append('rect'), nx = Array.isArray(x) ? x[column] ? x[column] : x[0] ? x[0] : 0 : x + (w * column), ny = Array.isArray(y) ? y[row] ? y[row] : y[0] ? y[0] : 0 : y + (h * row), nw = Array.isArray(width) ? width[0] ? width[0] : 0 : width / Columns, nh = Array.isArray(height) ? height[0] ? height[0] : 0 : height / Rows;
          d3Valid.num(nx) && grid.attr('x', nx);
          d3Valid.num(ny) && grid.attr('y', ny);
          d3Valid.num(w) && grid.attr('width', nw);
          d3Valid.num(h) && grid.attr('height', nh);
          grid.attr('stroke', style.stroke)
            .attr('stroke-width', style.strokeWidth)
            .attr("stroke-dasharray", style.strokeDashed)
            .attr('class', 'gridBox')
            .attr('hIndex', column)
            .attr('vIndex', row);
  
          gridBoxes.push(grid);
        }
      }
  
  
      if (style) {
        for (var index in style) {
          var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          node.attr(styleName, style[index]);
        }
      }
  
      var geo = {
        x: x,
        y: y,
        w: width,
        h: height
      };
  
      var grid = {};
      grid.node = node;
      grid.id = id;
      grid.geo = geo;
      grid.rows = Rows;
      grid.columns = Columns;
      grid.gridBoxes = gridBoxes;
      grid.parent = this;
      grid.active = false;
      grid.boxWidth = w;
      grid.boxHeight = h;
      grid.params = {
        id: id, x: x, y: y, width: width, height: height, Rows: Rows, Columns: Columns, style: style
      };
  
      var model = item.model;
      model.grid[id] = grid;
      item.grid = grid;
  
      return this;
    }
    //Parent.customGrid(id, x, y, width, height, Rows, Columns, style);
    customGrid(id, x, y, width, height, style) {
  
      var item = this;
  
      var node = item.node.append('g').attr('id', id);
  
      var w = width / Columns, h = height / Rows, gridBoxes = [];
  
      //console.log(Rows)
      for (var row = 0; row < Rows; row++) {
  
        for (var column = 0; column < Columns; column++) {
          var grid = node.append('rect');
          d3Valid.num(x + (w * column)) && grid.attr('x', x + (w * column));
          d3Valid.num(y + (h * row)) && grid.attr('y', y + (h * row));
          d3Valid.num(w) && grid.attr('width', w);
          d3Valid.num(h) && grid.attr('height', h);
          grid.attr('stroke', style.stroke)
            .attr('stroke-width', style.strokeWidth)
            .attr("stroke-dasharray", style.strokeDashed)
            .attr('class', 'gridBox')
            .attr('hIndex', column)
            .attr('vIndex', row);
  
          gridBoxes.push(grid);
        }
      }
  
  
      if (style) {
        for (var index in style) {
          var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          node.attr(styleName, style[index]);
        }
      }
  
      var geo = {
        x: x,
        y: y,
        w: width,
        h: height
      };
  
      var grid = {};
      grid.node = node;
      grid.id = id;
      grid.geo = geo;
      grid.rows = Rows;
      grid.columns = Columns;
      grid.gridBoxes = gridBoxes;
      grid.parent = this;
      grid.active = false;
      grid.boxWidth = w;
      grid.boxHeight = h;
  
      var model = item.model;
      model.grid[id] = grid;
      item.grid = grid;
  
      return this;
    }
    findGrid(parent, x, y) {
      var gridBoxes = parent.grid.gridBoxes;
      var grid = gridBoxes.find(function (item) {
        var gridX = parseFloat(item.attr('x')), gridY = parseFloat(item.attr('y'));
        return gridX == x && gridY == y;
      });
  
      return {
        "x": grid.attr('x'),
        "y": grid.attr('y'),
        "width": grid.attr('width'),
        "height": grid.attr('height'),
        "class": grid.attr('class'),
        "hIndex": grid.attr('hIndex'),
        "vIndex": grid.attr('vIndex'),
        "item": grid,
        "parent": parent
      };
    }
    //d3Vertex.prototype.setDimension(width, height, orientation, style)
    setDimension(id, x, y, width, height, orientation, text, style) {
      return new d3Dimension(this, id, x, y, width, height, orientation, text, style);
    }
    updateDimension(id, x, y, width, height, orientation, text, style) {
      var model = this.model;
      model.dimensions[id].node.remove();
      console.log(model.dimensions[id]);
      console.log(model);
      delete model.dimensions[id];
      return new d3Dimension(this, id, x, y, width, height, orientation, text, style);
    }
    getBBox(shape) {
      return new d3GRect(this, shape);
    }
    //getBoundingBoxRectangle End
    //------------------------------------------------------------------------
    //Added for Machanical Drawing
    visible(value) {
      var item = this;
      if (value) {
        item.shape.style('opacity', 1);
      } else {
        item.shape.style('opacity', 0);
      }
  
      return this;
    }
    fade(value) {
      var item = this;
      if (value) {
        item.shape.style('opacity', value);
      } else {
        item.shape.style('opacity', value);
      }
  
      return this;
    }
    editText(action) {
      var item = this, model = this.model;
  
      var textNode = item.node.select('text');
      var itemBBox = item.getBBox();
      var value = textNode.text();
  
      d3.select('.d3Textbox').remove();
  
      var textBox = d3.select(model.d3Graph.container).append('div').attr('id', 'd3TextBox' + item.id).attr('class', 'd3Textbox').attr('style', 'left: ' + itemBBox.left + 'px; top: ' + itemBBox.bottom + 10 + 'px;'), textField = textBox.append('input').attr('type', 'text').attr('id', 'd3textField').attr('class', 'd3textField').attr('value', value).attr('maxlength', 40), buttons = textBox.append('div').attr('class', 'd3TextboxButtons'), saveButton = buttons.append('div').attr('class', 'd3TextboxSave'), cancelButton = buttons.append('div').attr('class', 'd3TextboxCancel');
  
      document.getElementById("d3textField").select();
  
      d3.select("body").on('keydown', function () {
        if (d3.event.keyCode == 13) {
          saveTextBox(event);
        }
  
        if (d3.event.keyCode == 27) {
          cancelTextBox(event);
        }
      });
  
      textBox.on('click', function () {
        d3.event.stopPropagation();
      });
  
  
      saveButton.text('Save');
      cancelButton.text('Cancel');
  
      saveButton.on('click', function (event) {
        saveTextBox(event);
      });
  
      cancelButton.on('click', function (event) {
        cancelTextBox(event);
      });
  
      d3.select(document).on('click', function () {
        d3.select('.d3Textbox').remove();
      });
  
  
      function saveTextBox(event) {
        var newValue = document.getElementById("d3textField").value;
        if (newValue)
          textNode.text(newValue);
        textBox.remove();
        action.save(value, newValue, event);
      }
  
      function cancelTextBox(event) {
        textBox.remove();
        action.cancel(event);
      }
  
      item.textBox = textBox;
  
      return this;
    }
    show() {
      var item = this;
      item.node.style('display', 'block');
    }
    hide() {
      var item = this;
      item.node.style('display', 'none');
    }
  }
  
  var d3Shape = {
    rectangle: function (node, x, y, w, h) {
      var rectangle = node.append('rect');
      d3Valid.num(x) && rectangle.attr("x", x);
      d3Valid.num(y) && rectangle.attr("y", y);
      d3Valid.num(w) && rectangle.attr("width", w);
      d3Valid.num(h) && rectangle.attr("height", h);
      rectangle.attr("stroke", "#000000")
        .attr("stroke-width", 1)
        .attr("fill", "#FFFFFF");
      return rectangle;
    },
    circle: function (node, x, y, r) {
      var circle = node.append('circle');
      d3Valid.num(x) && circle.attr('cx', x);
      d3Valid.num(y) && circle.attr('cy', y);
      d3Valid.num(r) && circle.attr('r', r);
      circle.attr('fill', '#FFFFFF')
        .attr('stroke', '#000000')
        .attr('stroke-width', 1);
      return circle;
    },
    ellipse: function (node, cx, cy, rx, ry) {
      var ellipse = node.append('ellipse');
      d3Valid.num(cx) && ellipse.attr('cx', cx);
      d3Valid.num(cy) && ellipse.attr('cy', cy);
      d3Valid.num(rx) && ellipse.attr('rx', rx);
      d3Valid.num(ry) && ellipse.attr('ry', ry);
      ellipse.attr('fill', '#FFFFFF')
        .attr('stroke', '#000000')
        .attr('stroke-width', 1);
      return ellipse;
    },
    image: function (node, x, y, w, h, url) {
      var image = node.append('svg:image');
      d3Valid.str(url) && image.attr('xlink:href', url);
      d3Valid.num(x) && image.attr("x", x);
      d3Valid.num(y) && image.attr("y", y);
      d3Valid.num(w) && image.attr("width", w);
      d3Valid.num(h) && image.attr("height", h);
      return image;
    },
    line: function (node, point) {
      var line = node.append('line');
      d3Valid.num(point[0]) && line.attr("x1", point[0]);
      d3Valid.num(point[1]) && line.attr("y1", point[1]);
      d3Valid.num(point[2]) && line.attr("x2", point[2]);
      d3Valid.num(point[3]) && line.attr("y2", point[3]);
      line.attr('stroke', '#000000')
        .attr('stroke-width', 1);
      return line;
    },
    arc: function (node, point) {
      var arcPath = d3.line()
        .x(function (d) {
          return d[0];
        })
        .y(function (d) {
          return d[1];
        })
        .curve(d3.curveBasis);
  
      var arc = node.append('path');
      arcPath(point) && arc.attr("d", arcPath(point))
      arc.attr("point", point)
      arc.attr("stroke", "#000000")
        .attr("stroke-width", 1)
        .attr("fill", "none");
      return arc;
    },
    text: function (node, x, y, text) {
      var textShape = node.append('text');
      d3Valid.num(x) && textShape.attr("x", x);
      d3Valid.num(y) && textShape.attr("y", y);
      d3Valid.str(text) && textShape.text(text);
      textShape.attr('stroke-width', 0)
        .attr("fill", "#ffffff")
        .attr("font-size", "9px")
        .attr("font-family", "arial")
        .attr("font-weight", "normal")
      return textShape;
    },
    use: function (node, x, y, w, h) {
      var use = node.append('use');
      d3Valid.num(x) && useuse.attr("x", x);
      d3Valid.num(y) && use.attr("y", y);
      d3Valid.num(w) && use.attr("width", w);
      d3Valid.num(h) && use.attr("height", h);
      return use;
    }
  };
  
  
  
  
  
  
  class d3Label {
    constructor(x, y, w, h, id, node) {
      this.shapes = [];
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  function _moveAllChildren(item, d) {
    for (var j in item.children) {
      var child = item.children[j];
      if (child) {
        for (var i in child.nodeElements) {
          var shape = child.nodeElements[i];
          if (shape) {
            _updateAttr(shape, d);
            _updatePosition(child);
          }
        }
        if (child.grid) {
          for (var i in child.grid.gridBoxes) {
            var gridBox = child.grid.gridBoxes[i];
            _updateAttr(gridBox, d);
          }
  
          child.grid.geo.x = child.grid.parent.geo.x;
          child.grid.geo.y = child.grid.parent.geo.y;
        }
        if (child.children) {
          _moveAllChildren(child, d);
        }
      }
    }
  }
  
  
  
  function _updateAttr(shape, d) {
    var x = shape.attr('x'),
      y = shape.attr('y'),
      w = shape.attr('w'),
      h = shape.attr('h'),
      x1 = shape.attr('x1'),
      x2 = shape.attr('x2'),
      y1 = shape.attr('y1'),
      y2 = shape.attr('y2'),
      cx = shape.attr('cx'),
      cy = shape.attr('cy'),
      r = shape.attr('r'),
      p = shape.attr('point');
  
  
    var dx = parseFloat(x) + d.x,
      dy = parseFloat(y) + d.y,
      dx1 = parseFloat(x1) + d.x,
      dx2 = parseFloat(x2) + d.x,
      dx3 = parseFloat(cx) + d.x,
      dy1 = parseFloat(y1) + d.y,
      dy2 = parseFloat(y2) + d.y,
      dy3 = parseFloat(cy) + d.y,
      dp,
      dpa = [];
  
  
    var array = [];
    if (p) {
      var pa = p.split(',');
  
      dp = pa.map(function (a, i) {
        if (i % 2 === 0) {
          array = [];
          array.push(parseFloat(a) + d.x);
          return parseFloat(a) + d.x;
        } else {
          array.push(parseFloat(a) + d.y);
          dpa.push(array);
          return parseFloat(a) + d.y;
        }
      });
    }
  
    var arcPath = d3.line()
      .x(function (d) {
        return d[0];
      })
      .y(function (d) {
        return d[1];
      })
      .curve(d3.curveBasis);
  
  
    d3Valid.num(dx) && shape.attr("x", dx);
    d3Valid.num(dy) && shape.attr("y", dy);
    d3Valid.num(dx1) && shape.attr("x1", dx1);
    d3Valid.num(dx2) && shape.attr("x2", dx2);
    d3Valid.num(dx3) && shape.attr("cx", dx3);
    d3Valid.num(dy1) && shape.attr("y1", dy1);
    d3Valid.num(dy2) && shape.attr("y2", dy2);
    d3Valid.num(dy3) && shape.attr("cy", dy3);
    if (dp) {
      shape.attr("d", arcPath(dpa));
      shape.attr("point", dp);
    }
  
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  class d3Link {
    constructor(id, parent, source, target, type, style) {
      var sx = source.geo.x, sy = source.geo.y, sw = source.geo.w, sh = source.geo.h, tx = target.geo.x, ty = target.geo.y, tw = target.geo.w, th = target.geo.h;
      var sGeo = [sx, sy, sw, sh], tGeo = [tx, ty, tw, th];
  
      var linePath = drawLinePath(sGeo, tGeo, type);
  
  
      var node = parent.node.append('g');
  
      var line = node.append('path')
        .attr("d", linePath)
        .attr("stroke", style.stroke)
        .attr("stroke-width", style.strokeWidth)
        .attr("fill", "none");
  
  
      source.node && source.node.each(function () {
        this.parentNode.appendChild(this);
      });
  
      target.node && target.node.each(function () {
        this.parentNode.appendChild(this);
      });
  
      this.id = id;
      this.node = node;
      this.line = line;
      this.source = source;
      this.target = target;
      this.parent = parent;
      source.links[id] = this;
      target.links[id] = this;
      this.model = parent.model;
      this.model.links[id] = this;
      this.model.svgElements.push(line);
  
      return this;
    }
    insertLink(id, source, target, type, style) {
      return new d3Link(this, id, source, target, type, style);
    }
    show() {
      var item = this;
      item.node.style('display', 'block');
    }
    hide() {
      var item = this;
      item.node.style('display', 'none');
    }
  }
  
  
  
  
  
  
  
  
  
  function drawLinePath(sGeo, tGeo, type) {
    var x1 = (sGeo[0] + (sGeo[2] / 2)),
      y1 = (sGeo[1] + (sGeo[3] / 2)),
      x2 = (tGeo[0] + (tGeo[2] / 2)),
      y2 = (sGeo[1] + (sGeo[3] / 2)),
      x3 = (tGeo[0] + (tGeo[2] / 2)),
      y3 = (tGeo[1] + (tGeo[3] / 2));
  
    var linePath = [
      [x1, y1],
      [x3, y3]
    ];
  
    var lineType = '';
  
    if (type === 'line') {
      lineType = d3.curveLinear;
    } else if (type === 'step') {
      lineType = d3.curveStep;
    }
  
    var lineFunction = d3.line().curve(lineType);
  
    return lineFunction(linePath);
  }
  
  
  
  
  
  class d3ContextMenu {
    constructor(image, title, size, align, position, cursor) {
      this.image = image;
      this.title = title;
      this.size = (size !== null) ? size : [24, 24];
      this.align = (align !== null) ? align : ['top', 'left'];
      this.position = (position !== null) ? position : [0, 0];
      this.cursor = (cursor !== null) ? cursor : 'pointer';
      this.items = [];
      this.itemCount = 0;
      this.links = {};
    }
    addItem(name, icon, data, func, parent) {
      var itemDetails = {};
      this.itemCount++;
      itemDetails.index = this.itemCount - 1;
      itemDetails.name = name;
      itemDetails.icon = icon;
      itemDetails.data = data;
      itemDetails.action = func;
      itemDetails.parent = parent;
      itemDetails.node = '';
      itemDetails.hasSubmenu = false;
      this.items.push(itemDetails);
      return itemDetails;
    }
  }
  
  
  
  
  
  
  
  
  
  
  class d3Badge {
    constructor() {
      this.nodeElements = [];
    }
  };
  
  
  
  
  
  
  
  
  
  function _updatePosition(item) {
    if (item.shape) {
  
      var x = item.shape.attr('x'),
        y = item.shape.attr('y'),
        x1 = item.shape.attr('x1'),
        y1 = item.shape.attr('y1');
  
      if (x) item.geo.x = parseFloat(x);
      if (y) item.geo.y = parseFloat(y);
      if (x1) item.geo.x = parseFloat(x1);
      if (y1) item.geo.y = parseFloat(y1);
    }
  
    return item;
  };
  
  
  function updateGeo(item) {
    if (item.shape) {
      var x = parseFloat(item.shape.attr('x')),
        y = parseFloat(item.shape.attr('y')),
        w = parseFloat(item.shape.attr('width')),
        h = parseFloat(item.shape.attr('height'));
  
      item.geo.x = x;
      item.geo.y = y;
      item.geo.w = w;
      item.geo.h = h;
    }
  
    return item;
  };
  
  
  
  
  
  
  
  
  
  class d3TreeLayout {
    constructor(parent, id, x, y, w, h, style) {
      var node = parent.node.append('g')
        .attr('transform', 'translate(' + 0 + ', ' + 0 + ')')
        .attr('id', id)
        .attr('class', 'treeLayout')
        .attr('layout', 'tree')
        .attr('style', 'overflow: auto; width: ' + w + 'px; height: ' + h + 'px;');
  
      var nx = parent.geo.x + x, ny = parent.geo.y + y;
  
      var geo = {
        x: nx,
        y: ny,
        w: w,
        h: h
      };
  
      this.availableSpace = ny;
      this.type = 'treeLayout';
      this.id = id;
      this.node = node;
      this.geo = geo;
      this.style = style;
      this.parent = this;
      this.branches = {};
      this.children = {};
      this.nodeElements = [];
      this.model = parent.model;
      parent.treeLayout = this;
      parent.children[id] = this;
      this.model.treeLayouts[id] = this;
      this.treeLayout = this;
  
      return this;
    }
    branch(id, x, y, w, h, text, icon) {
      return new d3TreeBranch(this, id, x, y, w, h, text, icon);
    }
  };
  
  
  
  class d3TreeBranch {
    constructor(parent, id, x, y, w, h, value, icon, relative) {
  
      var vStyle, // dummy style to fill the parameter index
        style = parent.treeLayout.style, bx = parent.geo.x + style.branchAlignSpace, by = parent.treeLayout.availableSpace + style.verticalSpace, vertex = new d3Vertex(parent, 'group', id, bx, by, w, h, vStyle, relative), node = vertex.node, pointStyle = style.pointStyle, tx = bx + style.spaceAfterPoint, point;
  
      switch (pointStyle) {
        case 'circle':
          point = node.append('circle')
            .attr('cx', bx).attr('cy', by)
            .attr('r', style.pointSize)
            .attr('fill', style.pointColor)
            .attr('stroke', style.pointStrokeColor)
            .attr('stroke-width', style.pointStrokeWidth);
          break;
        case 'rectangle':
          point = node.append('rect')
            .attr('x', bx).attr('y', by)
            .attr('width', style.pointSize * 2)
            .attr('height', style.pointSize * 2)
            .attr('fill', style.pointColor)
            .attr('stroke', style.pointStrokeColor)
            .attr('stroke-width', style.pointStrokeWidth);
          break;
        case 'rounded':
          point = node.append('rect')
            .attr('x', bx).attr('y', by)
            .attr("rx", 3).attr("ry", 3)
            .attr('width', style.pointSize * 2)
            .attr('height', style.pointSize * 2)
            .attr('fill', style.pointColor)
            .attr('stroke', style.pointStrokeColor)
            .attr('stroke-width', style.pointStrokeWidth);
          break;
        case 'image':
          point = node.append('svg:image')
            .attr('xlink:href', icon)
            .attr("x", bx)
            .attr("y", by)
            .attr("width", style.pointSize * 2)
            .attr("height", style.pointSize * 2);
          break;
      }
  
  
  
      var trimmedValue;
      if (style.maxLength && value.length > style.maxLength) {
        trimmedValue = value.substring(0, style.maxLength);
        if (style.ellipsis) {
          trimmedValue += '...';
        }
      } else {
        trimmedValue = value;
      }
  
  
      var text = node.append('text').attr("x", tx).attr("y", by + 2.5).text(trimmedValue).attr('alignment-baseline', 'hanging').attr('fill', style.textColor);
  
      if (style) {
        for (var index in style) {
          var styleName = index.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
          node.attr(styleName, style[index]);
        }
      }
  
  
      var geo = {
        x: bx,
        y: by,
        w: w,
        h: h
      };
  
  
  
      this.children = {};
  
      this.treeLayout = parent.treeLayout;
      this.model = parent.model;
  
      this.treeLayout.nodeElements.push(point);
      this.model.svgElements.push(point);
      this.treeLayout.nodeElements.push(text);
      this.treeLayout.model.svgElements.push(text);
  
      this.treeLayout.availableSpace = by;
      this.type = 'treeBranch';
      this.geo = geo;
      this.parent = parent;
      this.id = id;
      this.text = text;
      this.icon = icon;
      this.node = node;
      this.pointNode = point;
      this.pointStyle = pointStyle;
      this.textNode = text;
      this.vertex = vertex;
  
      this.treeLayout.branches[id] = this;
  
      return this;
    }
    branch(id, x, y, w, h, text, icon) {
      return new d3TreeBranch(this, id, x, y, w, h, text, icon);
    }
    getVertex() {
      return this.vertex;
    }
  }
  
  
  
  
  
  function getParent(func, item) {
    for (var index in func.tree) {
      if (item === func.tree[index].id) {
        return func.tree[index];
      }
    }
  };
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  class d3Dimension {
    constructor(parent, id, x, y, width, height, orientation, style) {
  
      var node = parent.node.append('g').attr('id', id), start = {}, end = {}, center = {}, nodeElements = [], model = parent.model;
      if (orientation === 'vertical') {
  
        var startNodeV = node.append('line');
        d3Valid.num(x) && startNodeV.attr('x1', x);
        d3Valid.num(y) && startNodeV.attr('y1', y);
        d3Valid.num(x + width) && startNodeV.attr('x2', x + width);
        d3Valid.num(y) && startNodeV.attr('y2', y);
        startNodeV.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
        start.node = startNodeV;
        start.x1 = x;
        start.y1 = y;
        start.x2 = x + width;
        start.y2 = y;
  
        var endNodeV = node.append('line');
        d3Valid.num(x) && endNodeV.attr('x1', x);
        d3Valid.num(y + height) && endNodeV.attr('y1', y + height);
        d3Valid.num(x + width) && endNodeV.attr('x2', x + width);
        d3Valid.num(y + height) && endNodeV.attr('y2', y + height);
        endNodeV.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
        end.node = endNodeV;
        end.x1 = x;
        end.y1 = y + height;
        end.x2 = x + width;
        end.y2 = y + height;
  
        var sc = x + (width / 2);
  
        var centerNodeV = node.append('line');
        d3Valid.num(sc) && centerNodeV.attr('x1', sc);
        d3Valid.num(y) && centerNodeV.attr('y1', y);
        d3Valid.num(sc) && centerNodeV.attr('x2', sc);
        d3Valid.num(y + height) && centerNodeV.attr('y2', y + height);
        centerNodeV.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
        center.node = centerNodeV;
        center.x1 = sc;
        center.y1 = y;
        center.x2 = sc;
        center.y2 = y + height;
        nodeElements.push(startNodeV);
        nodeElements.push(endNodeV);
        nodeElements.push(centerNodeV);
  
      } else if (orientation === 'horizontal') {
  
        var startNodeH = node.append('line');
        d3Valid.num(x) && startNodeH.attr('x1', x);
        d3Valid.num(y) && startNodeH.attr('y1', y);
        d3Valid.num(x) && startNodeH.attr('x2', x);
        d3Valid.num(y + height) && startNodeH.attr('y2', y + height);
        startNodeH.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
        start.node = startNodeH;
        start.x1 = x;
        start.y1 = y;
        start.x2 = x;
        start.y2 = y + height;
  
        var endNodeH = node.append('line');
        d3Valid.num(x + width) && endNodeH.attr('x1', x + width);
        d3Valid.num(y) && endNodeH.attr('y1', y);
        d3Valid.num(x + width) && endNodeH.attr('x2', x + width);
        d3Valid.num(y + height) && endNodeH.attr('y2', y + height);
        endNodeH.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
        end.node = endNodeH;
        end.x1 = x;
        end.y1 = y + height;
        end.x2 = x + width;
        end.y2 = y + height;
  
        var sc = y + (height / 2);
  
        var centerNodeH = node.append('line');
        d3Valid.num(x) && centerNodeH.attr('x1', x);
        d3Valid.num(sc) && centerNodeH.attr('y1', sc);
        d3Valid.num(x + width) && centerNodeH.attr('x2', x + width);
        d3Valid.num(sc) && centerNodeH.attr('y2', sc);
        centerNodeH.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
        center.node = centerNodeH;
        center.x1 = x;
        center.y1 = sc;
        center.x2 = x + width;
        center.y2 = sc;
  
        nodeElements.push(startNodeH);
        nodeElements.push(endNodeH);
        nodeElements.push(centerNodeH);
      }
  
      var geo = {
        x: x,
        y: y,
        w: width,
        h: height
      };
  
      this.parent = parent;
      this.id = id;
      this.node = node;
      this.start = start;
      this.end = end;
      this.center = center;
      this.geo = geo;
      this.orientation = orientation;
      this.style = style;
      this.text = [];
      this.nodeElements = nodeElements;
  
      model.dimensions[id] = this;
  
      return this;
    }
    //position should be start, end, center or a number of x or y value
    addValue(text, position, positionValue) {
      var item = this, posValue = positionValue ? positionValue : 0;
      if (text) {
        var text1 = item.node.append('text').text(text)
          .attr('fill', this.style.textColor)
          .attr('font-family', this.style.fontFamily)
          .attr('font-size', this.style.fontSize)
          .attr('font-weight', this.style.fontWeight);
        var width = text1.node().getBBox().width;
        var x, y;
        if (item.orientation === 'vertical') {
          if (item.style.textAlign === 0) {
            x = item.center.x1 - (width + 10);
          } else {
            x = item.center.x1 + 10;
          }
  
  
          if (position === 'start') {
            y = item.center.y1 + posValue;
          } else if (position === 'end') {
            y = item.center.y2 - posValue;
          } else if (position === 'center') {
            y = item.center.y1 + (item.geo.h / 2);
          } else {
            y = item.center.y1 + position;
          }
  
        } else if (item.orientation === 'horizontal') {
          if (item.style.textAlign === 0) {
            y = item.center.y1 - posValue;
          } else {
            y = item.center.y1 + posValue;
          }
  
          if (position === 'start') {
            x = item.center.x1 + 10;
          } else if (position === 'end') {
            x = item.center.x2 - (width + 10);
          } else if (position === 'center') {
            x = item.center.x1 + (item.geo.w / 2);
          } else {
            x = item.center.x1 + position;
          }
        }
  
        d3Valid.num(x) && text1.attr('x', x);
        d3Valid.num(y) && text1.attr('y', y);
  
        this.text.push(text1);
        this.nodeElements.push(text1);
  
      }
  
      return this;
    }
    //Point should be a number which the x or y position to add as a point
    //Size should be a number which can be width or height of the point
    //align should be 0 = left/bottom, 1 = center, 2 = right/top
    addPoint(point, size, align, style) {
      var item = this, node;
      if (item.orientation === 'vertical') {
        var cy = item.center.y1, y = cy + point, x;
  
        if (align === 0) {
          x = item.center.x1;
        } else if (align === 1) {
          x = item.center.x1 - (size / 2);
        } else if (align === 2) {
          x = item.center.x1 - size;
        }
  
        node = item.node.append('line');
        d3Valid.num(x) && node.attr('x1', x);
        d3Valid.num(y) && node.attr('y1', y);
        d3Valid.num(x + size) && node.attr('x2', x + size);
        d3Valid.num(y) && node.attr('y2', y);
        node.attr('stroke', style.stroke)
          .attr('stroke-width', style.strokeWidth);
  
  
  
  
      } else if (item.orientation === 'horizontal') {
      }
  
      this.nodeElements.push(node);
  
      return this;
    }
    childAs(cell) {
      var item = this;
  
      if (cell) {
        cell.children[item.id] = item;
      }
  
      return this;
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  //------------------------------------------------------------------------
  
  //getBoundingBoxRectangle
  
  
  class d3GRect {
    constructor(cell, shape) {
      //    if(cell.type !== 'div'){
      //        cellBBox = shape ? shape.node().getBoundingClientRect() : cell.shape.node().getBBox();
      //    } else{
      //        cellBBox = shape ? shape.node().getBBox() : cell.shape.node().getBoundingClientRect();
      //    }
      var model = cell.model;
      var svgBBox = model.d3Graph.getBBox();
      var cellNode = cell.shape ? cell.shape : cell.node;
      var cellBBox = shape ? shape.node().getBoundingClientRect() : cellNode.node().getBoundingClientRect();
  
      var x = (cellBBox.x - svgBBox.x), y = (cellBBox.y - svgBBox.y), width = cellBBox.width, height = cellBBox.height, top = y, left = x, right = x + width, bottom = y + height;
  
      this.x = x;
      this.y = y;
      this.width = width;
      this.height = height;
      this.top = top;
      this.left = left;
      this.right = right;
      this.bottom = bottom;
  
      return this;
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  