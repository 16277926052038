var layout = layout || {};

layout.draw = function (data, element) {
 d3graphModel.emptyModel();
 var width = $("si-wizard").width();
 var height = $(window).height() - 175;
 var EMP = new EPM("#layoutContainer", element, width, height),
   EMP_DRAW_AREA = EMP.group("drawArea"),
   model = d3graphModel.getModel("#layoutContainer");

   console.log(EMP)

 EMP.zoomable(true, [0.1, 8], EMP_DRAW_AREA, false);

 var getProportion = (value) => {
    return parseFloat(value) * 5;
  };

  
  var parent = 2;

var cubicleIndex = 1,
  cubicleArray = [],
  mccbSECTION_A = [],
  mccbSECTION_B = [],
  mccbSECTION_C = [],
  mccbSECTION_D = [],
  mccbSECTION_E = [],
  totalCubicleWidth = 0;

  data.forEach((fd) => {
  var CUBICLE_TYPE = fd.properties.find((a) => {
    return a.name == "REQD_CUBICLE_TYPE";
  }).value;
  var MCCB_FULL_PANEL = fd.properties.find((a) => {
    return a.name == "MCCB_FULL_PANEL";
  })?.value;
  var MCCB_MODULE_HEIGHT = fd.properties.find((a) => {
    return a.name == "MCCB_MODULE_HEIGHT";
  })?.value;
  if (CUBICLE_TYPE.split("_")[0] === "ACB") {
    var CB_BD_LOC = fd.properties.find((a) => {
      return a.name == "CB_BD_LOC";
    }).value;
    var MBB_POS = fd.properties.find((a) => {
      return a.name == "MBB_POS";
    }).value;
    var FEEDER_AMP_RATING = fd.properties.find((a) => {
      return a.name == "FEEDER_AMP_RATING";
    }).value.split("A")[0];
    var comb1 = CUBICLE_TYPE + "+" + CB_BD_LOC;
    //console.log(comb1)
    var CUBICLE_TERM_LOC = CUBICLE_TERM_LOC_RULE__ACB[comb1][0];
    var comb2 =
      CUBICLE_TYPE +
      "+" +
      MBB_POS +
      "+" +
      FEEDER_AMP_RATING +
      "+" +
      CUBICLE_TERM_LOC;
    var CUBICLE_WIDTH = CUBICLE_WIDTH__CUBICLE_DEPTH__ACB[comb2][0];
    var FR_VIEW_MODEL_NAME =
      FR_VIEW_MODEL_NAME__ACB[CUBICLE_TYPE + "+" + FEEDER_AMP_RATING][0];
  }
  // --------------- MCCB ------------------
  //var CB_BD_LOC = fd.properties.find((a) => {return a.name == "CB_BD_LOC"}).value;
  // var MBB_POS = fd.properties.find((a) => {return a.name == "MBB_POS"}).value;
  // var FEEDER_AMP_RATING = fd.properties.find((a) => {return a.name == "FEEDER_AMP_RATING"}).value;
  // var comb1 = CUBICLE_TYPE+ "+" + CB_BD_LOC;
  //console.log(comb1)
  //var CUBICLE_TERM_LOC = CUBICLE_TERM_LOC_RULE__ACB[comb1][0];
  // var comb2 = CUBICLE_TYPE + "+" + MBB_POS + "+" + FEEDER_AMP_RATING + "+" + CUBICLE_TERM_LOC;

  //console.log(CUBICLE_TERM_LOC);

  var cubicleCountArray = [];
  var cubicleCount = 0;
  var section = 0;
  var mccCount = [];
  var ofpCubicleCount = [];
  var cubicleType = [];
  var moduleDetails = [];
  fd.properties.forEach((props) => {
    if (CUBICLE_TYPE.split("_")[0] === "ACB" || CUBICLE_TYPE === "EMPTY" || MCCB_FULL_PANEL.toString().toUpperCase() === "TRUE") {
      cubicleCount = parseFloat(
        CUBICLE_TYPE.split("_")[0] === "ACB" || CUBICLE_TYPE === "EMPTY"
          ? props.value
          : Math.ceil(props.value / 3)
      );
      if (props.name === "SECTION_A" && props.value) {
        cubicleCountArray.push(parseFloat(cubicleCount));
        mccCount.push(getMCCBCount(parseFloat(props.value)));
      } else if (props.name === "SECTION_B" && props.value) {
        cubicleCountArray.push(parseFloat(cubicleCount));
        mccCount.push(getMCCBCount(parseFloat(props.value)));
      } else if (props.name === "SECTION_C" && props.value) {
        cubicleCountArray.push(parseFloat(cubicleCount));
        mccCount.push(getMCCBCount(parseFloat(props.value)));
      } else if (props.name === "SECTION_D" && props.value) {
        cubicleCountArray.push(parseFloat(cubicleCount));
        mccCount.push(getMCCBCount(parseFloat(props.value)));
      } else if (props.name === "SECTION_E" && props.value) {
        cubicleCountArray.push(parseFloat(cubicleCount));
        mccCount.push(getMCCBCount(parseFloat(props.value)));
      }
    }else{
      if (props.name === "SECTION_A" && props.value) {
        var numberOfPanel = Math.ceil((parseFloat(props.value) * parseFloat(MCCB_MODULE_HEIGHT)) / 1400);
        ofpCubicleCount.push(Math.ceil(numberOfPanel / 2));
        cubicleType.push(getCubicleDesign(numberOfPanel));
        for (var i = 0; i < parseFloat(props.value); i++) {
          mccbSECTION_A.push({
            "width": 600,
            "height": parseFloat(MCCB_MODULE_HEIGHT)
          });
        }
      } else if (props.name === "SECTION_B" && props.value) {
        var numberOfPanel = Math.ceil((parseFloat(props.value) * parseFloat(MCCB_MODULE_HEIGHT)) / 1400);
        ofpCubicleCount.push(Math.ceil(numberOfPanel / 2));
        cubicleType.push(getCubicleDesign(numberOfPanel));
        for (var i = 0; i < parseFloat(props.value); i++) {
          mccbSECTION_B.push({
            "width": 600,
            "height": parseFloat(MCCB_MODULE_HEIGHT)
          });
        }
      } else if (props.name === "SECTION_C" && props.value) {
        var numberOfPanel = Math.ceil((parseFloat(props.value) * parseFloat(MCCB_MODULE_HEIGHT)) / 1400);
        ofpCubicleCount.push(Math.ceil(numberOfPanel / 2));
        cubicleType.push(getCubicleDesign(numberOfPanel));
        for (var i = 0; i < parseFloat(props.value); i++) {
          mccbSECTION_C.push({
            "width": 600,
            "height": parseFloat(MCCB_MODULE_HEIGHT)
          });
        }
      } else if (props.name === "SECTION_D" && props.value) {
        var numberOfPanel = Math.ceil((parseFloat(props.value) * parseFloat(MCCB_MODULE_HEIGHT)) / 1400);
        ofpCubicleCount.push(Math.ceil(numberOfPanel / 2));
        cubicleType.push(getCubicleDesign(numberOfPanel));
        for (var i = 0; i < parseFloat(props.value); i++) {
          mccbSECTION_D.push({
            "width": 600,
            "height": parseFloat(MCCB_MODULE_HEIGHT)
          });
        }
      } else if (props.name === "SECTION_E" && props.value) {
        var numberOfPanel = Math.ceil((parseFloat(props.value) * parseFloat(MCCB_MODULE_HEIGHT)) / 1400);
        ofpCubicleCount.push(Math.ceil(numberOfPanel / 2));
        cubicleType.push(getCubicleDesign(numberOfPanel));
        for (var i = 0; i < parseFloat(props.value); i++) {
          mccbSECTION_E.push({
            "width": 600,
            "height": parseFloat(MCCB_MODULE_HEIGHT)
          });
        }
      }

    }
  });

  console.log(cubicleCountArray);

  if(cubicleCountArray.length){
    cubicleCountArray.forEach((count, index) => {
      var secMcc = mccCount[index];
      var CUBICLE_HEIGHT = 200;
      var CUBICLE_POSY = 0;
      var DESCRIPTION = "";
      for (var i = 0; i < count; i++) {
        if (CUBICLE_TYPE.split("_")[0] !== "ACB") {
          if (CUBICLE_TYPE === "EMPTY") {
            CUBICLE_WIDTH = fd.properties.find((a) => {
              return a.name == "EMPTY_FEEDER_WIDTH";
            }).value;
            FR_VIEW_MODEL_NAME = FR_VIEW_MODEL_NAME__EMPTY[CUBICLE_WIDTH][0];
            DESCRIPTION = "EMPTY";
          } else {
            CUBICLE_WIDTH = CUBICLE_WIDTH__FR_VIEW_MODEL_NAME__MCCB[secMcc[i]][0];
            FR_VIEW_MODEL_NAME =
              CUBICLE_WIDTH__FR_VIEW_MODEL_NAME__MCCB[secMcc[i]][1];
              DESCRIPTION = FEEDER_AMP_RATING+"A";
          }
  
          console.log(CUBICLE_WIDTH);
          console.log(FR_VIEW_MODEL_NAME);
        }else{
          if(parseFloat(FEEDER_AMP_RATING.split("A")[0]) > 1600){
            CUBICLE_HEIGHT = 220;
            CUBICLE_POSY = -20
          }else{
            CUBICLE_HEIGHT = 200;
            CUBICLE_POSY = 0;
          }
          DESCRIPTION = FEEDER_AMP_RATING+"A";
        }
  
        var panelOrder =
          CUBICLE_TYPE === "ACB_IC" ? 1 : CUBICLE_TYPE === "ACB_BC" ? 3 : 2;
          totalCubicleWidth = totalCubicleWidth + parseFloat(CUBICLE_WIDTH);
        cubicleArray.push({
          name: "CUBICLE" + cubicleIndex,
          number: 0,
          parent: 2,
          type: CUBICLE_TYPE,
          usage: "CUBICLE",
          section: index + 1,
          order: panelOrder,
          ATTR: {
            HEIGHT: CUBICLE_HEIGHT,
            WIDTH: parseFloat(CUBICLE_WIDTH) / 10,
            DESCRIPTION: FEEDER_AMP_RATING+"A",
            POS_X: 0,
            POS_Y: CUBICLE_POSY,
            image: "assets/svg/" + FR_VIEW_MODEL_NAME + ".svg",
          },
        });
        cubicleIndex = cubicleIndex + 1;
      }
    });
}


if(ofpCubicleCount.length){
  ofpCubicleCount.forEach((count, index) => {
    var typeCubic = cubicleType[index];
    for (var i = 0; i < count; i++) {
      var CUBICLE_DESIGN = OFP_CUBICLE_DESIGN__MCCB[typeCubic[i]][0];
      var CUBICLE_WIDTH = CUBICLE_WIDTH__FR_VIEW_MODEL_NAME__MCCB__OFP[CUBICLE_TYPE+'+'+CUBICLE_DESIGN][0];
      var FR_VIEW_MODEL_NAME = CUBICLE_WIDTH__FR_VIEW_MODEL_NAME__MCCB__OFP[CUBICLE_TYPE+'+'+CUBICLE_DESIGN][1];
      console.log(CUBICLE_WIDTH)

      var panelOrder = 2;
      totalCubicleWidth = totalCubicleWidth + parseFloat(CUBICLE_WIDTH);
      cubicleArray.push({
        name: "CUBICLE" + cubicleIndex,
        number: 0,
        parent: 2,
        type: CUBICLE_TYPE,
        usage: "CUBICLE",
        section: index + 1,
        order: panelOrder,
        ATTR: {
          HEIGHT: 200,
          WIDTH: parseFloat(CUBICLE_WIDTH) / 10,
          DESCRIPTION: CUBICLE_TYPE.split("_")[0],
          POS_X: 0,
          POS_Y: 0,
          //image: "assets/svg/" + FR_VIEW_MODEL_NAME + ".svg",
        },
      });
      cubicleIndex = cubicleIndex + 1;

    }
  });
}
  // console.log(CUBICLE_TERM_LOC);
  // console.log(CUBICLE_WIDTH);
});

var arryOfMCCB = [mccbSECTION_A, mccbSECTION_B, mccbSECTION_C, mccbSECTION_D, mccbSECTION_E];

//cubicleArray.sort((a, b) => (a.section - b.section) && a.type.localeCompare(b.type));
cubicleArray
  //.sort((a, b) => a.type.localeCompare(b.type))
  .sort((a, b) => a.order - b.order)
  .sort((a, b) => a.section - b.section);
  var rootWidth = 0;

var tuArray = distributeToTU(cubicleArray, 240);
var pageData = [{
  name: "GADIAGRAM",
  number: 1,
  parent: -1,
  usage: "GADIAGRAM",
  ATTR: {
    HEIGHT: "",
    WIDTH: "",
    DESCRIPTION: "",
    POS_X: "0",
    POS_Y: "0"
  },
}
];
var pageHeight = 0;


for (var i = 0; i < Math.ceil(tuArray.length/2); i++) {
  var lastItem = pageData[pageData.length - 1];
  pageData.push({
    name: "TITTLE" + (i + 1),
    number: lastItem.number + 1,
    parent: -1,
    type: "TITTLE",
    usage: "TITTLE",
    order: i+1,
    tuIndex: i,
    ATTR: {
      HEIGHT: "766.0",
      WIDTH: "1083.5",
      DESCRIPTION: "",
      POS_X: "0",
      POS_Y: pageHeight,
      image: "assets/svg/template.svg",
    },
  },
  {
    name: "SWITCHBOARD",
    number: lastItem.number + 2,
    parent: lastItem.number + 1,
    type: "SWITCHBOARD",
    usage: "ROOT",
    tuIndex: i+1,
    ATTR: {
      HEIGHT: "200",
      WIDTH: "140",
      DESCRIPTION: "",
      POS_X: "120",
      POS_Y: "250",
    },
  });
  pageHeight = pageHeight + 866;
}


var tuPosX = 0
tuArray.forEach((tu, index) => {
  var tuIndex = Math.ceil((index + 1)/2);
  var lastItem = pageData[pageData.length - 1];
  var tuWidth = tu.reduce( function(a, b){
    return a + b.ATTR.WIDTH;
  }, 0);
  var availableNumber = lastItem.number + 2;
  var parent = pageData.find((item) => {return item.name === "SWITCHBOARD" && item.tuIndex === tuIndex});
    pageData.push({
      name: "TRANSPORTUNIT",
      number: lastItem.number + 1,
      parent: parent.number,
      type: "TRANSPORTUNIT",
      usage: "TRANSPORTUNIT",
      order: "",
      ATTR: {
        HEIGHT: 200,
        WIDTH: tuWidth,
        DESCRIPTION: "TRANSPORT UNIT",
        POS_X: tuPosX,
        POS_Y: 0
      },
    });
    var type = index % 2;
    tuPosX = type == 1 ? 0 : tuPosX + tuWidth;
    var posx = 0
  tu.forEach((cubic, index) => {
    tuWidth = tuWidth + cubic.ATTR.WIDTH;
    var cubicleIndex = cubicleArray.findIndex((cu) => {
      return cubic.name === cu.name;
    });

    var cubicle = cubicleArray[cubicleIndex];
    
    cubicle.number = availableNumber++;
    cubicle.parent = lastItem.number + 1;
    cubicle.ATTR.POS_X = posx;
    cubicle.ATTR.CUB_INDEX = index + 1;
    posx = posx + parseInt(cubicle.ATTR.WIDTH);
    pageData.push(cubicle);
    if(cubicle.type.split('_')[0] === "OFP"){
      var panelnumber = cubicle.number + 1;
      var panelPosx = {
        120: [20],
        220: [40, 120],
        80: [20],
        140: [0, 80]
      };
      var mainBusbar = {
        120: [[20, 0, 100, 37.5]],
        220: [[0, 0, 100, 37.5], [120, 0, 100, 37.5]],
        80: [[20, 0, 60, 37.5]],
        140: [[0, 0, 60, 37.5], [80, 0, 60, 37.5]]
      };
      var nutralBusbar = {
        120: [[20, 177.5, 60, 22.5]],
        220: [[40, 177.5, 60, 22.5], [120, 177.5, 60, 22.5]],
        80: [[20, 177.5, 60, 22.5]],
        140: [[0, 177.5, 60, 22.5], [80, 177.5, 60, 22.5]]
      };
      var verticalBusbar = {
        120: [0, 0, 20, 200],
        220: [100, 0, 20, 200],
        80: [0, 0, 20, 200],
        140: [60, 0, 20, 200]
      };
      var numberPanel = cubicle.ATTR.WIDTH === 120 || cubicle.ATTR.WIDTH === 80 ? 1 : 2;
      for (var i = 0; i < numberPanel; i++) {
        var panposx = panelPosx[cubicle.ATTR.WIDTH][i];
        var mainBusbarDe = mainBusbar[cubicle.ATTR.WIDTH][i];
        var nutralBusbarDe = nutralBusbar[cubicle.ATTR.WIDTH][i];
        var verticalBusbarDe = verticalBusbar[cubicle.ATTR.WIDTH];
        pageData.push({
          name: "PANEL",
          number: panelnumber,
          parent: cubicle.number,
          type: cubicle.type,
          usage: "PANEL",
          section: cubicle.section,
          order: "",
          availability: 1400,
          ATTR: {
            HEIGHT: 140,
            WIDTH: 60,
            DESCRIPTION: "MCCB",
            POS_X: panposx,
            POS_Y: 37.5
          },
        });
        pageData.push({
          name: "MAINBUSBAR",
          number: panelnumber + 1,
          parent: cubicle.number,
          type: "MAINBUSBAR",
          usage: "MAINBUSBAR",
          section: cubicle.section,
          order: "",
          ATTR: {
            HEIGHT: mainBusbarDe[3],
            WIDTH: mainBusbarDe[2],
            DESCRIPTION: "MAIN BUS",
            POS_X: mainBusbarDe[0],
            POS_Y: mainBusbarDe[1]
          },
        });
        pageData.push({
          name: "NUTRALBUSBAR",
          number: panelnumber + 2,
          parent: cubicle.number,
          type: "NUTRALBUSBAR",
          usage: "NUTRALBUSBAR",
          section: cubicle.section,
          order: "",
          ATTR: {
            HEIGHT: nutralBusbarDe[3],
            WIDTH: nutralBusbarDe[2],
            DESCRIPTION: "NUTRAL BUS",
            POS_X: nutralBusbarDe[0],
            POS_Y: nutralBusbarDe[1]
          },
        });
        pageData.push({
          name: "VERTICALBUSBAR",
          number: panelnumber + 3,
          parent: cubicle.number,
          type: "VERTICALBUSBAR",
          usage: "VERTICALBUSBAR",
          section: cubicle.section,
          order: "",
          ATTR: {
            HEIGHT: verticalBusbarDe[3],
            WIDTH: verticalBusbarDe[2],
            DESCRIPTION: "VERTICAL DROPPER",
            POS_X: verticalBusbarDe[0],
            POS_Y: verticalBusbarDe[1]
          },
        });
        availableNumber = panelnumber + 4;
        panelnumber = panelnumber + 4;
      }
      
    }
    
    rootWidth = rootWidth + cubicle.ATTR.WIDTH;
  });
});

console.log(tuArray)

arryOfMCCB.forEach((modules, index) => {
  var section = index + 1;
  var panels = pageData.filter((item) => {
    return item.name === "PANEL" && item.section === section
  });
  modules.sort((a, b) => b.height - a.height) 
   for (var i = 0; i < modules.length; i++) {
      var module = modules[i];
      var parentPanel = panels.find((p) => {
        return p.availability >= module.height
      });

        var lastItem = pageData[pageData.length - 1];
        var moduleposY = 1400 - parentPanel.availability;
        pageData.push({
          name: "OFP",
          number: lastItem.number + 1,
          parent: parentPanel.number,
          type: "OFP",
          usage: "MCCB",
          section: parentPanel.section,
          order: "",
          ATTR: {
            HEIGHT: module.height / 10,
            WIDTH: module.width / 10,
            DESCRIPTION: "MCCB",
            POS_X: 0,
            POS_Y: moduleposY / 10
          },
        });
        parentPanel.availability = parentPanel.availability - module.height;
      
  };
});

console.log(pageData)
console.log(rootWidth)
console.log(cubicleArray);


var transportUnit = 1;
Object.keys(pageData).forEach((key) => {
  var item = pageData[key],
    instanceData = {
      name: item.name,
      number: item.number,
      parent: item.parent,
      height: getProportion(item["ATTR"]["HEIGHT"]),
      width: getProportion(item["ATTR"]["WIDTH"]),
      description: item["ATTR"]["DESCRIPTION"],
      x: getProportion(item["ATTR"]["POS_X"]),
      y: getProportion(item["ATTR"]["POS_Y"]),
      index: item["ATTR"]["CUB_INDEX"] ? item["ATTR"]["CUB_INDEX"] : 0,
      image: item.ATTR.image,
      usage: item.usage,
      type: item.type
    },
    parent = model.vertices[item.parent];

  if (instanceData.parent === -1) {
    EMP_DRAW_AREA.instance(
      "rectangle",
      instanceData.number,
      instanceData.x,
      instanceData.y,
      instanceData.width,
      instanceData.height,
      styleShape,
      false
    )
      .setData(instanceData)
      .addShape(
        "image",
        {
          x: 0,
          y: 0,
          w: instanceData.width,
          h: instanceData.height,
          u: instanceData.image,
        },
        styleAdShape,
        false
      );
      if(instanceData.number === 2){
        model.data.root = instanceData.number;
      }
  } else {
    if(instanceData.type === "OFP" || instanceData.type === "VERTICALBUSBAR" || instanceData.type === "NUTRALBUSBAR" || instanceData.type === "MAINBUSBAR" || instanceData.usage === "PANEL"){
      styleShape.strokeWidth = 2;
    }else{
      styleShape.strokeWidth = 0;
    }
    var instance = parent.instance(
        "rectangle",
        instanceData.number,
        instanceData.x,
        instanceData.y,
        instanceData.width,
        instanceData.height,
        styleShape,
        true
      )
      .setData(instanceData);
      if(instanceData.type === "OFP"){
        instance.addLabel(instanceData.description, styleText);
      }
      if((instanceData.type && instanceData.type.split('_')[0] === "ACB") || (instanceData.type && instanceData.type.split('_')[0] === "EMPTY" || instanceData.type === "OFP")){
        //instance.addLabel(instanceData.description, styleText);
      }

      
    if (instanceData.image) {
      instance.addShape(
        "image",
        {
          x: 0,
          y: 0,
          w: instanceData.width,
          h: instanceData.height,
          u: instanceData.image,
        },
        styleAdShape,
        false
      );
    }

    if (instanceData.type === "SWITCHBOARD") {
      var dimension1 = instance.setDimension(
        "cub_dia_v"+instanceData.number,
        instance.geo.x - 80,
        instance.geo.y,
        50,
        instance.geo.h,
        "vertical",
        dimentionStyle
      ).addValue("2000", "center", 30);
    }

    if (instanceData.usage === "TRANSPORTUNIT") {
      var d1 = instance.setDimension(
        "cub_dia_h"+instanceData.number,
        instanceData.x + parent.geo.x,
        instanceData.y + parent.geo.y + instanceData.height + 100,
        instanceData.width,
        50,
        "horizontal",
        dimentionStyle
      ).addValue("TU-"+transportUnit, "center", 5);
      transportUnit = transportUnit + 1;
      //.addValue("TU - w" + parseFloat(item["ATTR"]["WIDTH"]) * 10, "center", 5);
    }

    if (instanceData.usage === "CUBICLE") {
      var d1 = instance.setDimension(
        "cub_dia_h"+instanceData.number,
        instanceData.x + parent.geo.x,
        instanceData.y + parent.geo.y + instanceData.height + 30,
        instanceData.width,
        50,
        "horizontal",
        dimentionStyle
      ).addValue("w" + parseFloat(item["ATTR"]["WIDTH"]) * 10, "center", 5);
      if(instanceData.type.split("_")[0] === "ACB"){
        styleText.fontSize = "20px"
        styleText.position = [0, -30];
        instance.addLabel(instanceData.description, styleText);
      }
    }else{
      styleText.position = [0, 0];
      styleText.fontSize = "45px"
    }

    if (instanceData.usage === "VENTILATOR_BLOCK") {
      var dimension1 = EMP_DRAW_AREA.setDimension(
        "dia3",
        instance.geo.x - 92.5,
        instance.geo.y,
        50,
        instance.geo.h,
        "vertical",
        dimentionStyle
      ).addValue("200", "center", 12);
    }
  }
});

console.log(model)

//defaultZoomLevel(model.vertices[model.data.root], model.d3Graph);
defaultZoomLevel();
function defaultZoomLevel(viewArea, EMP) {
  var viewArea = model.vertices[model.data.root];
  var EMP = model.d3Graph;
  if(viewArea){
  var svgBBox = EMP.getBBox(), //Svg Bounding Box
    sw = svgBBox.width, //290 = Workbench width
    sh = svgBBox.height,
    sx = svgBBox.x,
    sy = svgBBox.y,
    zoomScale = 1; // Default Zoom Scale

  var layoutBBox = viewArea.getBBox(), // Draw Area Bounding Box
    lw = model.backToBackConfig ? layoutBBox.width * 2 : layoutBBox.width,
    lh = layoutBBox.height,
    lx = layoutBBox.x,
    ly = layoutBBox.y;

  var dw = lw - sw, // width difference
    dh = lh - sh; // height difference

  var midX = lx + lw / 2,
    midY = ly + lh / 2;

  if (lw == 0 || lh == 0) return; // nothing to fit
  var scale = 0.95 / Math.max(lw / sw, lh / sh);
  var tx = sw / 2 - scale * midX;
  var ty = sh / 2 - scale * midY;

  var transform = d3.zoomIdentity.translate(tx, ty).scale(scale),
    zoom = model.zoomable.zoom;
  model.d3Graph.svg.call(zoom.transform, transform);
}
}

function getMCCBCount(n) {
  if (n <= 3) {
    return [n];
  } else {
    const base = 3;
    const additional = n - base;
    const result = [base];
    let remainder = additional;
    while (remainder > 0) {
      if (remainder >= base) {
        result.push(base);
        remainder -= base;
      } else {
        result.push(remainder);
        remainder = 0;
      }
    }
    return result;
  }
}


function getCubicleDesign(n) {
  let result = [];
  while (n > 0) {
      if (n >= 2) {
          result.push(2);
          n -= 2;
      } else {
          result.push(1);
          n -= 1;
      }
  }
  return result;
}


function distributeToTU(array, maxWidth) {
  const result = [];
  let currentSubArray = [];
  let currentSum = 0;

  array.forEach(cubicle => {
      if (currentSum + cubicle.ATTR.WIDTH <= maxWidth) {
          currentSubArray.push(cubicle);
          currentSum += cubicle.ATTR.WIDTH;
      } else {
          result.push(currentSubArray);
          currentSubArray = [cubicle];
          currentSum = cubicle.ATTR.WIDTH;
      }
  });

  if (currentSubArray.length > 0) {
      result.push(currentSubArray);
  }

  return result;
}

}
